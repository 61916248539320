import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { store } from "../../../store/store";
import { useStore, useStoreWithInitializer } from "../../../store/storeHooks";
import { changeCategory } from "../../aside/aside.slice";
import { ProductItem } from "../../product-item/product-item";
import { loadProducts, startLoadingProducts } from "./product-list.slice";
import "./product-list.scss";
import { useLocationChange } from "../../../utils/locationChange";
import { ProductService } from "../../../services/products.service";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../../utils/onScreen";
import { Spinner } from "../../common/spinner/spinner";
import { useTranslation } from "react-i18next";
import { localeString } from "../../../utils/localeString";
/* import { ArticleList } from '../article-list/article-list'; */
/* import UsersIcon from "./icons/3-user.svg" */
/* import WalletIcon from "./icons/wallet.svg" */
/* import DiscoveryIcon from "./icons/discovery.svg" */
import { NewProductList } from "./new-products/new-products";
import { toPrice } from "../../../utils/price";
import getImage from "../../../utils/image";
import LangSwitcher from "../../aside/lang-switcher/lang-switcher";
import { Helmet } from 'react-helmet';

function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export function ProductList(props: { locationChange: () => void }) {
    const { slug } = useParams<{ slug: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { products } = useStoreWithInitializer(
        ({ products }) => products,
        load
    );
    const { category } = useStore(({ category }) => ({ category }));
    const productService = new ProductService();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const [isLoading, setLoading] = useState(true);
    const [hasMore, setMore] = useState(false);
    const [page, setPage] = useState(1);

    useLocationChange((location: any) => {
        setPage(1);
        props.locationChange();
    });

    const debouncedSearchTerm = useDebounce(searchText, 500);
    const elementRef = useRef<HTMLDivElement>(null);
    const isOnScreen = useOnScreen(elementRef);

    const categories = useStore(({ category }) => category.categories);

    useEffect(() => {
        store.dispatch(startLoadingProducts());
        setMore(false);
    }, []);

    useEffect(() => {
        let isSubscribed: boolean = true;
        store.dispatch(changeCategory(slug));
        setLoading(true);
        /* setSearchText(searchParams.get('search')); **/
        setSearchText(searchParams.get("search") || "");
        setMore(false);

        productService
            .listProducts({
                category__slug: getSlug(),
                search: searchParams.get("search"),
                page: 1,
            })
            .then((res) => {
                if (isSubscribed) {
                    store.dispatch(loadProducts(res));
                    setLoading(false);
                    setMore(!!res.next);
                    setPage(1);
                }
            });
        return () => {
            isSubscribed = false;
            setPage(1);
            setMore(false);
            setLoading(true);
            store.dispatch(startLoadingProducts());
        };
    }, [slug, searchParams]);

    useEffect(() => {
        if (
            debouncedSearchTerm &&
            debouncedSearchTerm !== searchParams.get("search")
        ) {
            productService.listProducts({ search: searchText }).then((res) => {
                setShowSearch(true);
                setSearchResults(res.results);
            });
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (isOnScreen && hasMore && products.next) {
            const nextPage = page + 1;
            setPage(nextPage);
            listProducts({ page: nextPage });
        }
    }, [hasMore, isOnScreen, page, products.next]);


    async function load() {
        store.dispatch(startLoadingProducts());
        store.dispatch(changeCategory(slug));
    }

    function getSlug() {
        if (categories.map((obj) => obj.slug).includes(slug)) return slug;
        else return undefined;
    }

    function listProducts(params?: any) {
        setLoading(true);
        setMore(false);

        productService
            .listProducts({
                category__slug: getSlug(),
                search: searchParams.get("search"),
                ...params,
            })
            .then((res) => {
                const uniqueProducts = Array.from(
                    new Set(
                        [...products.results, ...res.results].map((product) => product.id)
                    )
                ).map((id) => {
                    return [...products.results, ...res.results].find(
                        (product) => product.id === id
                    );
                });

                store.dispatch(loadProducts({ ...res, results: uniqueProducts }));
                setLoading(false);
                setMore(!!res.next);
            });
    }

    const listItems: JSX.Element[] = products.results
        .filter((product) => !!!getSlug() || product.category.slug === slug)
        .map((product, key: number) => <ProductItem key={key} product={product} />);

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const selected_category = category.categories.find(
        (obj) => obj.slug === category.selectedCategory
    );


    const search_results = searchResults.slice(0, 5).map((product, key) => (
        <div
            className="search-results-item"
            key={key}
            onClick={() => {
                navigate(`/${product.category.slug}/${product.slug}`);
                setSearchResults([]);
                setShowSearch(false);
            }}
        >
            {product.discount && product.discount ? (
                <div className="search-results-item-discount">-{product.discount}%</div>
            ) : (
                <></>
            )}
            <img
                className="search-results-item-image"
                src={getImage(
                    product.image.image_thumb360 ? product.image.image_thumb360 : ""
                )}
                alt=""
            />
            <div className="search-results-item-name flex-fill mx-2">
                <h6>{t(localeString(product, "name"))}</h6>
                {/* <div>{t(localeString(product.category, 'name'))} </div> */}
            </div>
            {/* <div className='search-results-item-price-holder'>
                <h6 className='search-results-item-price white-space'>{toPrice(product.price)}</h6>
                {
                    product.discount && product.discount > 0 ? <h6 className='search-results-item-price white-space discount'>{toPrice(product.daily_price)}</h6> : <></>
                }
            </div> */}
        </div>
    ));

    const isMobile: boolean = window.innerWidth < 1024;
    return (
        <>
            <Helmet>
                <title>{selected_category ? t(localeString(selected_category, "name")) : "Product List"}</title>
                <meta name="description" content={selected_category ? `Список девайсов в категории ${t(localeString(selected_category, "name"))} category.` : "Список всех девайсов"} />
                <meta property="og:title" content={selected_category ? t(localeString(selected_category, "name")) : "Penumbra"} />
                <meta property="og:description" content={selected_category ? `Список девайсов в категории ${t(localeString(selected_category, "name"))} category.` : "List of all products."} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* SEARCH */}
            <form
                onClick={() => setShowSearch(false)}
                className="search-holder search-border"
                onSubmit={onSubmitHandler}
            >
                <input
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    placeholder={t("product.searching") || "Search" || "Placeholder"}
                />

                <div
                    className="penumbra-search"
                    style={{ fontSize: 10, opacity: 0.4 }}
                ></div>

                {showSearch && searchText ? (
                    <div className="search-results">
                        {searchResults.length > 0 ? (
                            <div className="search-results-list">{search_results}</div>
                        ) : (
                            <div className="d-flex p-3">{t("product.search_empty")}</div>
                        )}
                        {searchResults.length > 5 ? (
                            <button
                                className="btn btn-sm mx-0 btn-black mt-2"
                                onClick={() => {
                                    /* setSearchParams({ search: searchText }); */
                                    navigate(
                                        `/products?search=${encodeURIComponent(searchText)}`
                                    );
                                    setShowSearch(false);
                                }}
                            >
                                {t("show_all")}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </form>

            {selected_category || searchParams.get("search") ? (
                <></>
            ) : (
                <>
                    {/* NEW */}
                    <span className="mb-3">{t("main.all_products")}</span>
                </>
            )}

            {!isMobile ? <LangSwitcher /> : <></>}

            {selected_category && (
                <span className="mb-3">
                    {t(localeString(selected_category, "name"))}
                    {/* {t(localeString(selected_category, 'name'))} */}
                </span>
            )}

            <div className="product-list-holder animation-opacity">{listItems}</div>
            {!isLoading && products.results.length === 0 ? (
                <div
                    className="animation-opacity"
                    style={{ width: "100%", textAlign: "center" }}
                >
                    {t("product.empty")}
                </div>
            ) : (
                <></>
            )}
            <div ref={elementRef}></div>
            {isLoading ? <Spinner color="#fff" /> : <></>}
        </>
    );
}
