import { loadBasket } from "../components/pages/basket/basket.slice";
import { store } from "../store/store";
import axios from "axios";

export class BasketService {
  public async init(payload: any = {}): Promise<{ id: string }> {
    const res = await axios.post("/v1/bag/", payload);
    return res.data;
  }

  public _getBasket(uuid: string | null) {
    this.getBasket(uuid)
      .then((res) => {
        store.dispatch(loadBasket(res));
      })
      .catch((err) => {
        if (err.response.status === 404) {
          localStorage.removeItem("user_bag_id");
          this.init().then((res) => {
            localStorage.setItem("user_bag_id", res.id);
            this.getBasket(res.id).then((res) => {
              store.dispatch(loadBasket(res));
            });
          });
        }
      });
  }

  /* public async getBasket(uuid: string | null): Promise<any> {
   *   let res = null;
   *   if (uuid)
   *     res = await axios.get(`/v1/bag/${uuid}/`);
   *   return res.data;
   * } */

  public async getBasket(uuid: string): Promise<any> {
    const res = await axios.get(`/v1/bag/${uuid}/`);
    return res.data;
  }

  public async updateDelivery(uuid: string | null, payload: any): Promise<any> {
    const res = await axios.patch(`/v1/bag/${uuid}/`, payload);
    return res.data;
  }

  public async postProduct(uuid: string | null, payload: any): Promise<any> {
    const res = await axios.post(`/v1/bag2/${uuid}/products/`, payload);
    return res.data;
  }

  public async patchProduct(
    uuid: string | null,
    id: number | undefined,
    payload: any
  ): Promise<any> {
    const res = await axios.patch(`/v1/bag/${uuid}/products/${id}/`, payload);
    return res.data;
  }

  public async deleteProduct(
    uuid: string | null,
    id: number | undefined
  ): Promise<undefined> {
    const res = await axios.delete(`/v1/bag/${uuid}/products/${id}/`);
    return res.data;
  }

  public async patchProductAndUpdate(
  uuid: string | null,
  id: number | undefined,
  payload: any
): Promise<any> {
  const res = await axios.patch(`/v1/bag2/${uuid}/products/${id}/`, payload);

    return res.data;
}

public async deleteProductAndUpdate(
  uuid: string | null,
  id: number | undefined
): Promise<undefined> {
  const res = await axios.delete(`/v1/bag2/${uuid}/products/${id}/`);

  // If the deletion was successful, dispatch the updated UserBag to your store
  if (res.status === 204) {
    store.dispatch(loadBasket(res));
  }

  return res.data;
    }

    public async createOrder(uuid: string | null, payload: any): Promise<any> {
        const res = await axios.post(`/v1/bag/${uuid}/order/`, payload);
    return res.data;
  }

  public async applyCoupon(uuid: string | null, payload: any): Promise<any> {
    const res = await axios.post(`/v1/bag/${uuid}/apply-coupon/`, payload);
    return res.data;
  }

  public async downloadExcel(uuid: string | null): Promise<any> {
    const res = await axios.get(`/v1/bag/excel_full/${uuid}/`);
    return res.data;
  }

  public async patchProducts(
    uuid: string | null,
    payload: any[]
  ): Promise<any> {
    const res = await axios.patch(`/v1/bag/${uuid}/products/`, payload);
    return res.data;
  }

  public async postProductAndFetchUpdatedBasket(
    uuid: string | null,
    payload: any
  ): Promise<any> {
    const res = await axios.post(`/v1/bag3/${uuid}/products/`, payload);
    return res.data;
  }
}
