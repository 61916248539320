export const basket = {
    to_basket:
        "\u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0 \u00A0себетке \u00A0\u00A0 \u00A0",
    clear_basket: "себетті босату",
    total_price: "↳",
    delivery: "Жеткізу:",
    helm: "Себет",
    discount: "Купон",
    products_price: "Аспаптар",
    services_price: "Қызметкерлер және көлік",
    duriation: "Жалдау мерзімі:",
    rent_dates: "{{dateStart}} - {{dateEnd}}",
    coupon_applied: "Купон қолданылды",
    coupon_input: "Купон?",
    download_xlsx: "Cметаны жүктеу  ↓",
    first_time: "Мен техниканы бірінші рет алып отырмын",
    deliver_back: "Жеткізіп, алып кету",
    same_address: "Бір адрес",
    empty_list_error:
        "Себет бос!<br /> Каталогқа оралыңыз және қажетті жабдықты таңдаңыз",
    empty_list_error_back: "Басты бетке оралу",
    header: {
        name: "Aтауы",
        count: "Саны",
        shifts: "Ауысым",
        price: "Баға",
    },
    form: {
    header: "Мәліметтерді толтырыңыз",
    name_placeholder: "Аты-жөніңізді енгізіңіз",
    address_placeholder: "Жеткізу мекенжайын енгізіңіз",
    address_return_placeholder: "Қайтару мекенжайын енгізіңіз",
    address_message: "жеткізу аймағымен танысыңыз",
    pickup_option: "Өзіммен әкету",
    delivery_option: "Жеткізу",
    delivery_map_location:
      "Біз Саин - Әл-фараби - ШАЖ - Райымбек Батыр шаршысында жеткіземіз",
    phone_placeholder: "Телефон нөмірін енгізіңіз",
    comment_placeholder: "Пікір...",
    rent_period: "Жалдау мерзімін таңдаңыз",
    rent_start: "Басталуы",
    rent_finish: "Аяқталуы",
    date_placeholder: "кк.аа.жжжж",
  },
  required_for: "Міндетті",
  order: "Брондау",
  empty_list: "Себет бос",
  clear_all: "барлығы",
  deleting: {
    message: "Cіз жоясыз: ",
    cancel: "бас тарту",
    delete: "жою",
    clear_all: "барлығын жою",
  },
  success_message: "Жабдық сәтті брондалды!",
  success_message_10min: "Біздің менеджер сізбен жақын арада хабарласады",
  success_message_hint:
    "күту ұзағырақ болса, біз келесі жалға алуға 3% жеңілдік береміз",
};
