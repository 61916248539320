import { store } from "../../../store/store";
import { useStore } from "../../../store/storeHooks";
import {
  startLoadingCategoryProducts,
  loadCategoryProducts,
} from "./category-product-list.slice";
import "./category-product-list.scss";
import { useLocationChange } from "../../../utils/locationChange";
import { ProductService } from "../../../services/products.service";
import { useEffect, useState } from "react";
import { Spinner } from "../../common/spinner/spinner";
import { useTranslation } from "react-i18next";
import { localeString } from "../../../utils/localeString";
import { toPrice } from "../../../utils/price";
/* import infoIcon from "./../../../icons/warning.svg" */
import { useNavigate } from "react-router";

function ProductItem(props: { product: any }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setList: JSX.Element[] | undefined = props.product.set_products?.map(
    (item, key) => (
      <li key={key}>
        <span className="as">{t(localeString(item, "name"))}</span>
      </li>
    )
  );

  const serviceList: string[] | undefined = props.product?.services.map(
    (item) => localeString(item.service, "name")
  );

  return (
    <div
      className="category-product"
      onClick={() => {
        navigate(`/_/${props.product.slug}`);
      }}
    >
      <div className="info-holder">
        <div className="d-flex flex-fill">
          <img
            className="info-icon"
            src={
              props.product.image.image_thumb720
                ? props.product.image.image
                : ""
            }
            alt=""
          />
          <div className="info">
            <span>{t(localeString(props.product, "name"))}</span>
            <ul className="mt-2">{setList}</ul>
          </div>
        </div>
        <div className="info-price flex-fill">
          {toPrice(props.product.price)}
        </div>
        {props.product.type === 2 ? (
          <div className="set">{t("product.set")}</div>
        ) : (
          <></>
        )}
      </div>
      {props.product.services.length > 0 ? (
        <div className="d-flex info-services">
          {/* <img src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Circle-icons-mail.svg" alt="" /> */}
          ❕
          <span className="mx-1">
            {t("product.required_service")}{" "}
            {serviceList ? serviceList.join(", ") : ""}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function ProductList(props: { products: any[] }) {
  const productList = props.products.map((product, key) => (
    <ProductItem product={product} key={key} />
  ));

  return <div className="my-3 category-product-list">{productList}</div>;
}

export function CategoryProductList(props: { locationChange: () => void }) {
  const [index, setIndex] = useState(0);
  const { categoryProducts } = useStore(({ categoryProducts }) => ({
    categoryProducts,
  }));
  const productService = new ProductService();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);

  useLocationChange((location: any) => {
    props.locationChange();
  });

  useEffect(() => {
    store.dispatch(startLoadingCategoryProducts());
    setLoading(true);
    productService.categoryProductList().then((res) => {
      store.dispatch(loadCategoryProducts(res));
      setLoading(false);
    });
  }, []);

  const isMobile: boolean = window.innerWidth < 1024;

  const categoriesView: JSX.Element[] = categoryProducts.products.map(
    (category, key) => (
      <div key={key}>
        {isMobile ? (
          <></>
        ) : (
          <div className="category-item">
            <img
              src={category.icon}
              alt={t(localeString(category, "name")) || "placeholder"}
              className="icon"
            />
            <span className="category">
              {t(localeString(category, "name"))}
            </span>
          </div>
        )}
        {isMobile && index !== key ? (
          <></>
        ) : (
          <ProductList products={category.products} />
        )}
      </div>
    )
  );

  const mobileCategories: JSX.Element[] = categoryProducts.products.map(
    (category, key) => (
      <div
        className={`category-item ${index === key ? "selected" : ""}`}
        key={key}
        onClick={() => setIndex(key)}
      >
        <img
          src={category.icon}
          alt={t(localeString(category, "name")) || "placeholder"}
          className="icon"
        />
        <span className="category">{t(localeString(category, "name"))}</span>
      </div>
    )
  );

  return isLoading ? (
    <Spinner color="#fff" />
  ) : (
    <>
      {isMobile ? (
        <div className="category-holder">{mobileCategories}</div>
      ) : (
        <></>
      )}
      {categoriesView}
    </>
  );
}
