import "./header.scss";
/* import icon from './../aside/icon.svg' */
/* import menuIcon from './menu.svg' */
import { Link } from "react-router-dom";
import { useStore } from "../../store/storeHooks";

export function Header(prop: { onMenu: () => void }) {
  const { category } = useStore(({ category }) => ({ category }));
  return (
    <div className="header">
      <Link aria-label="Home Page" to={`/`}>
        <img className="icon" src="/Logo.png" alt="" />
      </Link>
      <button
        aria-label="Mobile Side Menu"
        className="btn p-0"
        onClick={() => prop.onMenu()}
      >
        {/* <img src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Circle-icons-mail.svg" alt="" /> */}
        <div className="icon penumbra-question"></div>
      </button>
    </div>
  );
}
