/* import avatar from './../assets/images/background.png' */
/* import background from './../assets/images/background.png' */

export function ImageField(src: string | null | undefined): string | undefined {
  if (src) {
    return src;
  }
  return undefined;
}

export default function getImage(
  image: string,
  temp: "avatar" | "background" = "background"
) {
  if (!!image) {
    return image;
  }
  return getImageTemp(temp);
}

export function getImageTemp(temp: "avatar" | "background" = "background") {
  switch (temp) {
    case "avatar":
      /* return avatar */
      return "https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png";
    case "background":
      return "https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png";
    /* return background */
  }
}
