import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Footer from "./components/footer/footer";
import { Aside } from "./components/aside/aside";
import { ProductList } from "./components/pages/product-list/product-list";
import { ProductPage } from "./components/pages/product/product";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BasketPage } from "./components/pages/basket/basket";
import { store } from "./store/store";
import { startLoadingBasket } from "./components/pages/basket/basket.slice";
import { BasketService } from "./services/basket.service";
import { useStore, useStoreWithInitializer } from "./store/storeHooks";
/* import { FaqList } from './components/pages/faq/faq'; */
import { Header } from "./components/header/header";
import { Naviagation } from "./components/navigation/naviagation";
import { CategoryList } from "./components/pages/category-list/category-list";
import { CategoryFront } from "./components/pages/category-list/category-front";
import Div100vh, { use100vh } from "react-div-100vh";
import FooterMobile from "./components/footer/mobile/footer-mobile";
import { CategoryProductList } from "./components/pages/category-product-list/category-product-list";
import ProductAddPage from "./components/pages/magic/product-add-page";
import EquipmentRequestPage from "./components/pages/sub/sub";
/* import { ArticlePage } from './components/pages/article/article';
 * import { CommunityPage } from './components/pages/community/community';
 * import { PadborPage } from './components/pages/podbor/podbor';
 * import { GrantPage } from './components/pages/grant/grant'; */
import { CategoriesService } from "./services/category.service";
import {
    loadCategories,
    startLoadingCategories,
} from "./components/aside/aside.slice";
import { Spinner } from "./components/common/spinner/spinner";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

ReactGA.initialize("G-QYE7SF287H");

function App() {
    const scrollToRef = (ref: any) =>
        ref.current?.scroll({
            top: 0,
            behavior: "smooth",
        });
    const location = useLocation();
    const { basket } = useStore(({ basket }) => ({ basket }));


    useEffect(() => {
        // This will be called whenever the location changes
        ReactGA.send({ hitType: 'pageview', page_path: location.pathname });
    }, [location]);

    function scroll() {
        scrollToRef(contentRef);
        setMenu(false);
    }

    const [highlight, setHighlight] = useState(false);
    const contentRef = useRef(null);
    const basketService = new BasketService();

    const { t } = useTranslation();

    useEffect(() => {
        if (basket.products.length > 0) {
            setHighlight(true);
            const timer = setTimeout(() => {
                setHighlight(false);
            }, 500); // Duration of the highlight animation

            return () => clearTimeout(timer);
        }
    }, [basket.products.length]);


    useEffect(() => {
        scrollToRef(contentRef);
        const uuid = localStorage.getItem("user_bag_id");
        store.dispatch(startLoadingBasket());
        if (!!!uuid) {
            basketService.init().then((res) => {
                localStorage.setItem("user_bag_id", res.id);
            });
        } else {
            basketService._getBasket(uuid);
        }
    }, []);

    const [menu, setMenu] = useState(false);

    const height = use100vh();
    const mobile = height ? height - 120 : "calc(100vh - 120px)";
    const desktop = height ? height : "100vh";
    const h = window.innerWidth > 1024 ? desktop : mobile;

    const categoryService = new CategoriesService();
    const { categories } = useStoreWithInitializer(
        ({ category }) => category,
        load
    );
    const encodedEmoji = encodeURIComponent("🛒");

    function load() {
        store.dispatch(startLoadingCategories());
        categoryService.listCategories().then((list) => {
            store.dispatch(loadCategories(list));
        });
    }

    return (
        <>
            {categories.length > 0 ? (
                <>
                    <Div100vh className="wrapper">
                        <Aside />
                        <Header onMenu={() => setMenu(!menu)} />
                        <div className="right-side" ref={contentRef} style={{ height: h }}>
                            <div className="content">
                                {basket.products.length > 0 &&
                                    !location.pathname.includes(`/${encodedEmoji}`) ? (
                                    <div
                                        className={`basket-outer ${highlight ? "highlight" : ""}`}
                                    >
                                        <Link to={`/🛒`} className="basket-link bordered-btn">
                                            <div className="basket-text-container">
                                                <h6
                                                    className="moving-text"
                                                    data-text={t("basket.to_basket")}
                                                >
                                                    {t("basket.to_basket")}
                                                </h6>
                                            </div>
                                        </Link>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="content-holder">
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<CategoryFront locationChange={scroll} />}
                                        />
                                        <Route
                                            path="/categories/"
                                            element={<CategoryList locationChange={scroll} />}
                                        />
                                        {/* <Route path='/articles/:slug/' element={<ArticlePage locationChange={scroll} />} /> */}
                                        <Route
                                            path="/:slug/"
                                            element={<ProductList locationChange={scroll} />}
                                        />
                                        <Route
                                            path="/:slug/:productSlug/"
                                            element={<ProductPage locationChange={scroll} />}
                                        />
                                        <Route
                                            path="/products/all/"
                                            element={<CategoryProductList locationChange={scroll} />}
                                        />
                                        <Route
                                            path="/basket/"
                                            element={<BasketPage locationChange={scroll} />}
                                        />
                                        <Route
                                            path="/🛒/"
                                            element={<BasketPage locationChange={scroll} />}
                                        />
                                        <Route path="/magic/" element={<ProductAddPage />} />
                                        <Route path="/sub/" element={<EquipmentRequestPage />} />
                                        {/* <Route path='/faqs/' element={<FaqList locationChange={scroll} />} /> */}
                                        {/* <Route path='/community/' element={<CommunityPage locationChange={scroll} />} /> */}
                                        {/* <Route path='/podbor/' element={<PadborPage locationChange={scroll} />} /> */}
                                        {/* <Route path='/grant/' element={<GrantPage locationChange={scroll} />} /> */}
                                    </Routes>
                                </div>
                            </div>
                            <Footer />
                        </div>
                        <Naviagation />
                    </Div100vh>
                </>
            ) : (
                <div className="app-spinner-holder">
                    <Spinner color="#fff" />
                </div>
            )}
            <div className={`side ${menu ? "slide-in" : "slide-out"}`}>
                <div className="side-outer" onClick={() => setMenu(false)}></div>
                <div className="side-menu animation-slide">
                    <FooterMobile />
                </div>
            </div>
        </>
    );
}

export default App;
