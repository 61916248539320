import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18n_ru } from "./ru/ru";
import { i18n_kk } from "./kk/kk";
import { i18n_en } from "./en/en";

function getDefaults() {
  return {
    order: [
      "querystring",
      "cookie",
      "localStorage",
      "sessionStorage",
      "navigator",
      "htmlTag",
    ],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    caches: ["localStorage"],
    excludeCacheFor: ["cimode"],
  };
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ru: i18n_ru,
      kk: i18n_kk,
      en: i18n_en,
    },
    fallbackLng: "ru",
    detection: getDefaults(),
    supportedLngs: ["ru", "kk", "en"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
