import "../../../../main.css";
import moment from "moment";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { hours, minutes } from "../../../../utils/times";
import { FreeMode, Mousewheel } from "swiper";

export function initialTime(): string {
  const time = moment().format("HH:mm");
  let [hours, minutes] = time.split(":");
  if (0 < +minutes && +minutes < 30) {
    minutes = "30";
  } else if (30 < +minutes) {
    minutes = "00";
    hours = `${+hours + 1}`;
  }
  if (+hours > 21) {
    hours = "21";
  } else if (+hours < 9) {
    hours = "09";
  }
  return moment().format(`${hours}:${minutes}`);
}

export default function TimePicker(prop: {
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
  onChange: (timeStart: string, timeEnd: string) => void;
}) {
  const [timeStart, setTimeStart] = useState<string>(initialTime);
  const [timeEnd, setTimeEnd] = useState<string>(initialTime);

  const [startTimePicker, setStartTimePicker] = useState(false);
  const [finishTimePicker, setFinishTimePicker] = useState(false);

  const selectedStartHour: string = timeStart.split(":")[0];
  const selectedStartMinutes: string = timeStart.split(":")[1];

  const selectedFinishHour: string = timeEnd.split(":")[0];
  const selectedFinishMinutes: string = timeEnd.split(":")[1];

  const startToday =
    moment(prop.dateStart).format("DD.MM.YY") ===
    moment(new Date()).format("DD.MM.YY");
  const endToday =
    moment(prop.dateEnd).format("DD.MM.YY") ===
    moment(new Date()).format("DD.MM.YY");
  const hourToday: number = +moment(new Date()).format("HH");
  const minutesToday: number = +moment(new Date()).format("mm");

  function timeDisabled(time: number): boolean {
    return prop.dateStart && timeStart
      ? startToday
        ? hourToday * 60 + minutesToday < time * 60
        : true
      : true;
  }

  function timeFinishDisabled(time: number): boolean {
    return prop.dateStart && timeStart
      ? endToday
        ? hourToday * 60 + minutesToday < time * 60
        : true
      : true;
  }

  const startHourList: JSX.Element[] = hours.map((hour, key) => (
    <SwiperSlide key={key}>
      <button
        onClick={() => {
          let time = "";
          if (+hour === 21) {
            time = `${hour}:00`;
          } else {
            time = `${hour}:${selectedStartMinutes}`;
          }
          setTimeStart(time);
          prop.onChange(time, timeEnd);
        }}
        disabled={!timeDisabled(+hour)}
        className={`btn btn-tiny ${
          +selectedStartHour === +hour ? "selected" : ""
        }`}
        key={key}
      >
        {hour}
      </button>
    </SwiperSlide>
  ));

  const startMinutesList: JSX.Element[] = minutes.map((minute, key) => (
    <button
      disabled={+selectedStartHour * 60 + +minute > 21 * 60}
      onClick={() => {
        const time = `${selectedStartHour}:${minute}`;
        setTimeStart(time);
        prop.onChange(time, timeEnd);
        setStartTimePicker(false);
      }}
      className={`btn btn-tiny ${
        +selectedStartMinutes === +minute ? "selected" : ""
      }`}
      key={key}
    >
      {minute}
    </button>
  ));

  const finishHourList: JSX.Element[] = hours.map((hour, key) => (
    <SwiperSlide key={key}>
      <button
        onClick={() => {
          let time = "";
          if (+hour === 21) {
            time = `${hour}:00`;
          } else {
            time = `${hour}:${selectedStartMinutes}`;
          }
          setTimeEnd(time);
          prop.onChange(timeStart, time);
        }}
        disabled={!timeFinishDisabled(+hour)}
        className={`btn btn-tiny ${
          +selectedFinishHour === +hour ? "selected" : ""
        }`}
        key={key}
      >
        {hour}
      </button>
    </SwiperSlide>
  ));

  const finishMinutesList: JSX.Element[] = ["00", "30"].map((minute, key) => (
    <button
      disabled={+selectedFinishHour * 60 + +minute > 21 * 60}
      onClick={() => {
        const time = `${selectedFinishHour}:${minute}`;
        setTimeEnd(time);
        prop.onChange(timeStart, time);
        setFinishTimePicker(false);
      }}
      className={`btn btn-tiny ${
        +selectedFinishMinutes === +minute ? "selected" : ""
      }`}
      key={key}
    >
      {minute}
    </button>
  ));

  return (
    <div className="d-flex">
      <div
        className="form-input calendar margin-left btn-input"
        style={{ padding: 0 }}
      >
        <button
          style={{ padding: "8px 12px" }}
          onClick={() => setStartTimePicker(!startTimePicker)}
          disabled={!!!prop.dateStart}
          className="btn btn-0 margin-left"
        >
          {timeStart}
        </button>
        {startTimePicker ? (
          <div className="time-picker">
            <Swiper
              className="time-picker-hour"
              direction={"vertical"}
              freeMode={{
                enabled: true,
                momentum: true,
                sticky: true,
              }}
              noSwiping={true}
              modules={[Mousewheel, FreeMode]}
              spaceBetween={4}
              slidesPerView={3}
              mousewheel={{
                forceToAxis: true,
                sensitivity: 0.2,
              }}
            >
              {startHourList}
            </Swiper>
            <div className="time-picker-minutes">{startMinutesList}</div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className="form-input calendar margin-right btn-input"
        style={{ padding: 0 }}
      >
        <button
          style={{ padding: "8px 12px" }}
          disabled={!!!prop.dateEnd}
          onClick={() => setFinishTimePicker(!finishTimePicker)}
          className="btn btn-0 margin-left"
        >
          <span>{timeEnd}</span>
        </button>
        {finishTimePicker ? (
          <div className="time-picker">
            <Swiper
              className="time-picker-hour"
              direction={"vertical"}
              freeMode={{
                enabled: true,
                momentum: true,
                sticky: true,
              }}
              modules={[Mousewheel, FreeMode]}
              spaceBetween={4}
              slidesPerView={3}
              mousewheel={{
                forceToAxis: true,
                sensitivity: 0.2,
              }}
            >
              {finishHourList}
            </Swiper>
            <div className="time-picker-minutes">{finishMinutesList}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
