import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ArticleListState {
  articles: any[];
  loaded: boolean;
}

const initialState: ArticleListState = {
  articles: [],
  loaded: false,
};

const slice = createSlice({
  name: "article_list",
  initialState,
  reducers: {
    startLoadingArticles: () => initialState,
    loadArticles: (state, { payload: articles }: PayloadAction<any[]>) => {
      state.articles = articles;
      state.loaded = true;
    },
  },
});

export const { startLoadingArticles, loadArticles } = slice.actions;

export default slice.reducer;
