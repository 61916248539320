import React, { useState } from "react";
import { ProductService } from '../../../services/products.service'; // Import your service
import { BasketService } from '../../../services/basket.service'; // Import your service
import { Spinner } from "../../common/spinner/spinner"; // Import your spinner

const productService = new ProductService(); // Initialize your service
const basketService = new BasketService(); // Initialize your service

const ProductAddPage = () => {
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [error, setError] = useState(null); // Initialize error state
    const uuid = localStorage.getItem("user_bag_id");

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true); // Set loading state to true when the request starts
        setError(null); // Clear any existing errors when a new request is made
        productService.addProductsFromText(uuid, "Список оборудования:" + text.replace('\n', ' '))
            .then(response => {
                basketService._getBasket(uuid);
                console.log(response);
            })
            .catch(error => {
                console.error("There was an error!", error);
                setError(error.message); // Set error state if an error occurs
            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false when the request finishes, either successfully or with an error
            });
    };

    return (
        <div>
            <h2>Add Products from Text</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Do magic
                    <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </label>
                <button type="submit" disabled={isLoading}>go</button> {/* Optionally disable the button while loading */}
            </form>
            {isLoading && <Spinner color="#fff" />} {/* Show the spinner while loading */}
            {error && <div className="error-message">{error}</div>} {/* Show error message when an error occurs */}
        </div>
    );
};

export default ProductAddPage;
