import { BarLoader } from 'react-spinners';

export function Spinner(props: { color: string; fullWidth?: boolean;}) {
  const fullWidth = {
    width: '100% !impotant'
  };

  return (
      <div className="spinner-wrapper" style={props.fullWidth ? { width: '100%' } : null}>
          <BarLoader
              cssOverride={props.fullWidth ? fullWidth : null}
              color="#ffc000"
              loading={true}
          />
      </div>
      );
}

Spinner.defaultProps = {
    smol: false
};
