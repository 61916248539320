import "./product-item.scss";
import { Link } from "react-router-dom";
import { toPrice } from "../../utils/price";
import { useNavigate } from "react-router";
import { BasketService } from "../../services/basket.service";
import { useStore } from "../../store/storeHooks";
import { localeString } from "../../utils/localeString";
import { useTranslation } from "react-i18next";
import getImage from "../../utils/image";
import { loadBasket } from "../../components/pages/basket/basket.slice";
import { store } from "../../store/store";
import { useState } from "react";
import ReactGA from "react-ga4";


export function ProductItem(prop: {
    product: any;
    border?: boolean;
    active?: boolean;
}) {
    const { t } = useTranslation();
    const [isAddingToBasket, setIsAddingToBasket] = useState(false);
    const basketService = new BasketService();
    const [count, setCount] = useState<number>(1);
    const uuid = localStorage.getItem("user_bag_id");
    const { basket } = useStore(({ basket }) => ({ basket }));


    async function sendBasketNew() {
        setIsAddingToBasket(true); // set to true when starting the request

        const payload = {
            shifts: 1,
            count: count,
            product: prop.product.id,
        };

        basketService
            .postProductAndFetchUpdatedBasket(uuid, payload)
            .then((updatedBasket) => {
                ReactGA.event({
                    category: 'Basket',
                    action: 'Added Product',
                    label: prop.product?.name_en,
                    value: parseInt(prop.product?.price)
                });
                store.dispatch(loadBasket(updatedBasket));
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    localStorage.removeItem("user_bag_id");
                    basketService.init().then((res) => {
                        localStorage.setItem("user_bag_id", res.id);
                        basketService.getBasket(res.id).then((res) => {
                            store.dispatch(loadBasket(res));
                        });
                    });
                }
            })
            .finally(() => {
                setIsAddingToBasket(false); // set to false when done, regardless of success or failure
            });
    }

    async function sendBasket() {
        const payload = {
            shifts: 1,
            count: count,
            product: prop.product.id,
        };
        basketService.postProduct(uuid, payload).then(() => {
            basketService._getBasket(uuid);
        });
    }
    const navigate = useNavigate();

    const onBasket = () => {
        navigate("/🛒");
    };

    const product = prop.product;
    const productIds = basket.products.map((prod) => prod.product.id);

    return (
        <div
            className={`product-item ${prop.border ? "product-item-border" : ""} ${prop.active ? "active" : ""
                } ${window.innerWidth <= 1024 ? "active" : ""}`}
        >
            {/* {product.discount ? (<div className="discount-percent">-{product.discount}%</div>) : <></>} */}
            <Link to={`/${product.category.slug}/${product.slug}`}>
                <img
                    draggable="false"
                    className="image"
                    src={getImage(product.image?.image_thumb720)}
                    alt={product.name_ru || "Product Name"}
                />

                <div className="info-holder">
                    <div className="info-name-holder">
                        <span className="info-name overflow overflow-2">
                            {/* {product.name_ru} */}
                            {t(localeString(product, "name"))}
                            {product.type === 2 ? (
                                <div className="complekt">{t("product.set")}</div>
                            ) : (
                                <></>
                            )}
                        </span>
                    </div>
                    <div className="d-flex align-items-end justify-content-center">
                        <span
                            className={`info-price overflow ${product.discount ? "bold" : ""
                                }`}
                        >
                            {toPrice(product.price)}
                        </span>
                        {product.discount ? (
                            <span className="info-price overflow dicount">
                                {toPrice(product.daily_price)}
                            </span>
                        ) : (
                            <></>
                        )}
                        {product.new ? (
                            <span className="info-price overflow new">
                                new
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Link>
            {
                productIds.includes(product.id) ? (
                    <button
                        className="info-btn bordered-btn mx-2 btn btn-black animation-opacity"
                        onClick={() => onBasket()}
                    >
                        <span>{t("product.to_card")}  </span>
                    </button>
                ) : (
                    <button
                        className="info-btn bordered-btn mx-2 btn btn-black animation-opacity"
                        onClick={() => sendBasketNew()}
                        disabled={isAddingToBasket}
                    >
                        <span className="product-item-span">
                            {product.amount > 1 ? (
                                <>
                                    <button
                                        disabled={basket.products.map(obj => obj.product.id).includes(product.id) || count === 1}
                                        className='btn icon'
                                        style={{ padding: "8px" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            count > 1 ? setCount(count - 1) : setCount(count)
                                        }}>
                                        <div className='penumbra-minus' style={{ fontSize: 12 }}></div>
                                    </button>

                                    {t("product.add_card")} {count > 1 ? count : null}

                                    <button
                                        disabled={basket.products.map(obj => obj.product.id).includes(product.id) || product.amount <= count}
                                        className='btn icon'
                                        style={{ padding: "8px" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            count < product!.amount ? setCount(count + 1) : setCount(count)
                                        }}>
                                        <div className='penumbra-add' style={{ fontSize: 12 }}></div>
                                    </button>


                                </>
                            ) : (<>{t("product.add_card")}  {count > 1 ? count : null} </>)}

                        </span>
                    </button>
                ) // disable the button when request is ongoing
            }
        </div>
    );
}
