import "./lang-switcher.scss";
import i18n from "./../../../i18n/i18n";

export default function LangSwitcher() {
  return (
    <div className="lang-switcher">
      <a
        className={i18n.language === "kk" ? "active" : ""}
        onClick={() => i18n.changeLanguage("kk")}
      >
        kz
      </a>
      <a
        className={i18n.language === "ru" ? "active" : ""}
        onClick={() => i18n.changeLanguage("ru")}
      >
        ru
      </a>
      <a
        className={i18n.language === "en" ? "active" : ""}
        onClick={() => i18n.changeLanguage("en")}
      >
        en
      </a>
    </div>
  );
}
