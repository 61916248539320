import { useStore } from "../../../store/storeHooks";
import { localeString } from "../../../utils/localeString";
import "./category-list.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocationChange } from "../../../utils/locationChange";
import { ImageField } from "../../../utils/image";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductItem } from "../../product-item/product-item";
import { ProductService } from "../../../services/products.service";
import LangSwitcher from "../../aside/lang-switcher/lang-switcher";
import getImage from "../../../utils/image";
import { Helmet } from "react-helmet";

function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export function CategoryFront(props: { locationChange: () => void }) {
    const { t } = useTranslation();
    const isMobile: boolean = window.innerWidth < 1024;

    const { category } = useStore(({ category }) => ({ category }));
    const [searchText, setSearchText] = useState("");
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const navigate = useNavigate();
    const productService = new ProductService();
    const debouncedSearchTerm = useDebounce(searchText, 500);

    useLocationChange((location: any) => {
        props.locationChange();
    });

    useEffect(() => {
        if (debouncedSearchTerm) {
            productService.listProducts({ search: searchText }).then((res) => {
                setShowSearch(true);
                setSearchResults(res.results);
            });
        }
    }, [debouncedSearchTerm]);

    const search_results = searchResults.slice(0, 5).map((product, key) => (
        <div
            className="search-results-item"
            key={key}
            onClick={() => {
                navigate(`/${product.category.slug}/${product.slug}`);
                setSearchResults([]);
                setShowSearch(false);
            }}
        >
            {product.discount && product.discount ? (
                <div className="search-results-item-discount">-{product.discount}%</div>
            ) : (
                <></>
            )}
            <img
                className="search-results-item-image"
                src={getImage(
                    product.image.image_thumb360 ? product.image.image_thumb360 : ""
                )}
                alt=""
            />
            <div className="search-results-item-name flex-fill mx-2">
                <h6>{t(localeString(product, "name"))}</h6>
                {/* <div>{t(localeString(product.category, 'name'))} </div> */}
            </div>
            {/* <div className='search-results-item-price-holder'>
                <h6 className='search-results-item-price white-space'>{toPrice(product.price)}</h6>
                {
                    product.discount && product.discount > 0 ? <h6 className='search-results-item-price white-space discount'>{toPrice(product.daily_price)}</h6> : <></>
                }
            </div> */}
        </div>
    ));

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const sortOrder = [
        "cameras",
        "lenses",
        "lighting",
        "support",
        "lens-accesoires",
        "monitors",
        "grip",
    ];

    const categoryList = category.categories
        .slice()
        .sort((a, b) => {
            let indexA = sortOrder.indexOf(a.slug);
            let indexB = sortOrder.indexOf(b.slug);

            if (indexA === -1) indexA = Number.MAX_VALUE;
            if (indexB === -1) indexB = Number.MAX_VALUE;

            return indexA - indexB;
        })
        .map((cat, key) => (
            <div className={`product-item`} key={key}>
                <Link to={`/${cat.slug}`}>
                    <img
                        draggable="false"
                        className="image"
                        src={ImageField(cat?.icon)}
                        alt={cat.name_ru || "Category Name"}
                    />
                    <div className="info-name-holder">
                        <span className="info-name overflow overflow-2 category-front-name">
                            {t(localeString(cat, "name"))}{" "}
                        </span>
                    </div>
                </Link>
            </div>
        ));

    return (
        <>
            <Helmet>
                <title>Penumbra</title>
                <meta name="description" content="Penumbra - аренда профессионального кинооборудования в Алматы." />
                <meta property="og:title" content="Penumbra" />
                <meta property="og:description" content="Penumbra - аренда профессионального кинооборудования в Алматы." />
                <meta property="og:image" content="/Logo.png" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <form
                onClick={() => setShowSearch(false)}
                className="search-holder search-border"
                onSubmit={onSubmitHandler}
            >
                <input
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    placeholder={t("product.searching") || "Search" || "Placeholder"}
                />
                <div
                    className="penumbra-search"
                    style={{ fontSize: 10, opacity: 0.4 }}
                ></div>

                {showSearch && searchText ? (
                    <div className="search-results">
                        {searchResults.length > 0 ? (
                            <div className="search-results-list">{search_results}</div>
                        ) : (
                            <div className="d-flex p-3">{t("product.search_empty")}</div>
                        )}
                        {searchResults.length > 5 ? (
                            <button
                                className="btn btn-sm mx-0 btn-black mt-2"
                                onClick={() => {
                                    navigate(
                                        `/products?search=${encodeURIComponent(searchText)}`
                                    );
                                    setShowSearch(false);
                                }}
                            >
                                {t("show_all")}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </form>

            {!isMobile ? <LangSwitcher /> : <></>}

            <span className="mb-3">{t("categories_screen")}</span>
            <div className="product-list-holder category-front-holder">
                {categoryList}
            </div>
        </>
    );
}
