import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductListState {
  products: any[];
}

const initialState: ProductListState = {
  products: [],
};

const slice = createSlice({
  name: "categoryProducts",
  initialState,
  reducers: {
    startLoadingCategoryProducts: () => initialState,
    loadCategoryProducts: (
      state,
      { payload: products }: PayloadAction<any[]>
    ) => {
      state.products = products;
    },
  },
});

export const { startLoadingCategoryProducts, loadCategoryProducts } =
  slice.actions;

export default slice.reducer;
