import "../basket-item/delete/basket-item-delete.scss";
import { BasketService } from "../../../../services/basket.service";
import { useCountdown } from "../../../../utils/countdown";
import { useEffect } from "react";
import { localeString } from "../../../../utils/localeString";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export function BasketClearDelete({
  className,
  products,
  onCancel,
  resetFields,
}: {
  className?: string;
  products: any[];
  onCancel: () => void;
  resetFields: () => void;
}) {
  const { t } = useTranslation();
  const basketService = new BasketService();
  const uuid: string | null = localStorage.getItem("user_bag_id");
  const time = useCountdown(10);
  const navigate = useNavigate();

  useEffect(() => {
    if (time === 0) {
      onClear();
    }
  }, [time]);

  async function onClear() {
    onCancel();
      Promise.all(products.map(item => {
          return basketService.deleteProduct(uuid, item.id);
    })).then(() => {
      basketService._getBasket(uuid);
      navigate('/');
    });
    resetFields();
    }

  return (
    <div
      className={`basket-item-holder basket-item-holder-delete animate-fade ${className}`}
    >
      <div className="d-flex align-items-center" style={{ width: "100%" }}>
        <div className="countdown">{time}</div>
        <div className="basket-item-info">
          <div className="message overflow">
            {t("basket.deleting.message")} {t("basket.clear_all")}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <button className="btn btn-black white-space" onClick={onCancel}>
          {t("basket.deleting.cancel")}
        </button>
        <button
          className="btn btn-black white-space"
          style={{ marginLeft: 12 }}
          onClick={onClear}
        >
          {t("basket.deleting.clear_all")}
        </button>
      </div>
    </div>
  );
}
