import "./product-specs.scss";
import { localeString } from "../../../../utils/localeString";
import { useTranslation } from "react-i18next";

export function ProductDetailSpecs(prop: { product: any }) {
  const { t } = useTranslation();

  const eov = prop.product.specs.map((item, key) => (
    <div className="specification-table">
      <div className="attributes">
        <p className="overflow" key={key}>
          {t(localeString(item.attribute, "attribute"))}
        </p>
      </div>
      <div className="values">
        <p key={key}>{t(localeString(item, "value"))}</p>
      </div>
    </div>
  ));

  return (
    <div className="specification">
      <h4 className="mb-2">{t("product.specs_header")}</h4>
      {eov}
    </div>
  );
}
