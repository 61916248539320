export const basket = {
  to_basket:
    "\u00A0 \u00A0перейти в корзину \u00A0\u00A0  \u00A0  \u00A0перейти в корзину \u00A0 \u00A0перейти в корзину \u00A0 \u00A0перейти в корзину \u00A0\u00A0\u00A0 ⟶ \u00A0  \u00A0перейти в корзину \u00A0 \u00A0перейти в корзину \u00A0 \u00A0перейти в корзину \u00A0\u00A0\u00A0 ⟶",
  clear_basket: "очистить корзину",
  total_price: "↳",
  helm: "Корзина",
  delivery: "Доставка:",
  discount: "Скидка",
  products_price: "Девайсы",
  services_price: "Персонал и авто",
  duriation: "Cрок аренды:",
  coupon_applied: "Купон применён",
  coupon_input: "Купон?",
  download_xlsx: "Скачать смету  ↓",
  rent_dates: "c {{dateStart}} по {{dateEnd}}",
  first_time: "Я беру технику в первый раз",
  deliver_back: "Туда и обратно",
  same_address: "Один адрес",
  header: {
    name: "Название",
    count: "Единиц",
    shifts: "Смен",
    price: "Цена",
  },
  form: {
    header: "Заполните данные",
    name_placeholder: "Имя",
    address_placeholder: "Адрес",
    address_return_placeholder: "Адрес возврата",
    address_message: "ознакомьтесь с зоной доставки",
    pickup_option: "Самовывоз",
    delivery_option: "Доставка",
    delivery_map_location:
      "Мы доставляем в квадрате Саина - Аль-фараби - ВОАД - Райымбек Батыра",
    phone_placeholder: "Телефон",
    comment_placeholder: "Комментарии",
    rent_period: "Период аренды",
    rent_start: "Начало",
    rent_finish: "Конец",
    date_placeholder: "дд.мм.гггг",
  },
  empty_list_error:
    "Корзина пуста!<br />Вернитесь в каталог и выберите нужную технику",
  empty_list_error_back: "Вернуться на главную",
  required_for: "Обязателен",
  order: "Забронировать",
  empty_list: "Корзина пустая",
  clear_all: "всё из корзины",
  deleting: {
    message: "Вы удаляете ",
    cancel: "отмена",
    delete: "удалить",
    clear_all: "удалить всё",
  },
  success_message: "Оборудование успешно забронировано!",
  success_message_10min: "Наш менеджер скоро свяжется с вами",
  success_message_hint:
    "если ожидание займет больше времени мы подарим скидку 3% на следующую аренду",
};
