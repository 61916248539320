import { footer } from "./footer.locale";
import { basket } from "./basket.locale";
import { product } from "./product.locale";
import { grant } from "./grant.locale";

export const i18n_ru = {
  translation: {
    _locale: "",
    basket,
    product,
    footer,
    grant,
    main_screen: "Главная",
    categories_screen: "Все категории",
    basket_screen: "Корзина",
    faq: "Вопросы и ответы",
    request_call: "Позвонить",
    have_questions: "У вас есть вопросы?<br>Пожалуйста свяжитесь с нами",
    main: {
      payment: "Оплата",
      community: "Комьюнити",
      podbor: "Подбор",
      new_products: "Новинки",
      all_products: "Всё оборудование",
    },
    in_process: "В процессе",
    show_all: "Смотреть все",
  },
};
