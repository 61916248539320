import "./naviagation.scss";
import { Link } from "react-router-dom";
import { useStore } from "../../store/storeHooks";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export function Naviagation() {
  const { t } = useTranslation();
  const { basket } = useStore(({ basket }) => ({ basket }));
  const { category } = useStore(({ category }) => ({ category }));
  const history = useLocation();

  const [location, setLocation] = useState<"main" | "categories" | "basket">();

  useEffect(() => {
    if (history.pathname.includes("categories")) {
      setLocation("categories");
      return;
    }
    if (history.pathname.includes("basket")) {
      setLocation("basket");
      return;
    }
    setLocation("main");
  }, [history]);

  return (
    <div className="navigation">
      <Link
        to={`/`}
        className={`navigation-item ${location === "main" ? "active" : ""}`}
      >
        <div className="icon penumbra-home"></div>
        <div className="name">{t("main_screen")}</div>
      </Link>
      <Link
        to={`/categories`}
        className={`navigation-item ${
          location === "categories" ? "active" : ""
        }`}
      >
        <div className="icon penumbra-category"></div>
        <div className="name">{t("categories_screen")}</div>
      </Link>
      <Link
        to={`/basket`}
        className={`navigation-item ${location === "basket" ? "active" : ""}`}
      >
        <div className="icon penumbra-bag"></div>
        {basket.products.length > 0 ? (
          <div className="basket-count animate-jump">
            {basket.products.length}
          </div>
        ) : (
          <></>
        )}
        <div className="name">{t("basket_screen")}</div>
      </Link>
    </div>
  );
}
