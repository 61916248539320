import "./basket-service-item.scss";
import { toPrice } from "../../../../utils/price";
import { localeString } from "../../../../utils/localeString";
import { useTranslation } from "react-i18next";
import listServiceProducts from "../../../../utils/serviceProducts";
import { ImageField } from "../../../../utils/image";

export function BasketProductServiceItem(prop: {
  service: any;
  products: any[];
}) {
  const { t } = useTranslation();
  const products: JSX.Element[] = listServiceProducts(
    prop.service,
    prop.products
  ).map((product) => {
    return <> {t(localeString(product, "name"))} </>;
  });
  const mobile: boolean = window.innerWidth <= 1024;
  return (
    <div className="basket-item-holder animate-fade">
      {/* <img className='basket-item-image' src={ImageField(prop.service.image?.image)} alt="" /> */}
      <div
        className="basket-item-info basket-item-service"
        style={{ paddingLeft: mobile ? 8 : 0 }}
      >
        <div className="name overflow">
          {" "}
          {t(localeString(prop.service, "name"))}{" "}
        </div>
        <div className="category">
          {t("basket.required_for")} {/* {products.join(', ')} */}
        </div>
      </div>
      <div className="basket-item-price basket-item-service-price">
        <div className="overall overflow">
          {toPrice(prop.service.daily_price)}
        </div>
      </div>
      <div style={{ width: 48, display: mobile ? "none" : "inherit" }}></div>
    </div>
  );
}
