import { useStore } from "../../../store/storeHooks";
import { localeString } from "../../../utils/localeString";
import "./category-list.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocationChange } from "../../../utils/locationChange";
import { ImageField } from "../../../utils/image";

export function CategoryList(props: { locationChange: () => void }) {
  const { t } = useTranslation();
  const { category } = useStore(({ category }) => ({ category }));

  useLocationChange((location: any) => {
    props.locationChange();
  });

  const sortOrder = [
    "cameras",
    "lenses",
    "lighting",
    "support",
    "lens-accesoires",
    "monitors",
    "grip",
  ];

  const categoryList = category.categories
    .slice()
    .sort((a, b) => {
      let indexA = sortOrder.indexOf(a.slug);
      let indexB = sortOrder.indexOf(b.slug);

      if (indexA === -1) indexA = Number.MAX_VALUE;
      if (indexB === -1) indexB = Number.MAX_VALUE;

      return indexA - indexB;
    })
    .map((cat, key) => (
      <Link to={`/${cat.slug}`} className="mobile-menu-item" key={key}>
        {/* <img className="icon" src={ImageField(cat?.icon)} alt="" /> */}
        <span>{t(localeString(cat, "name"))} </span>
      </Link>
    ));
  return <div className="mobile-menu">{categoryList}</div>;
}
