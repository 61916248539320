import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import "./index.scss";
import App from "./App";
import axios from "axios";

/* axios.defaults.baseURL = "http://127.0.0.1:8000/"; */
axios.defaults.baseURL = "https://penumbraback-production-d698.up.railway.app/";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
