import { footer } from "./footer.locale";
import { basket } from "./basket.locale";
import { product } from "./product.locale";
import { grant } from "./grant.locale";

export const i18n_kk = {
  translation: {
    _locale: "",
    product,
    basket,
    footer,
    grant,
    main_screen: "Басты бет",
    categories_screen: "Барлық cанаттар",
    basket_screen: "Себет",
    faq: "Сұрақтар мен жауаптар",
    request_call: "Қоңырау шалу",
    have_questions: "Сұрақтар бар ма?<br>Бізге хабарласуды сұраймыз",
    main: {
      payment: "Төлем",
      community: "Қауымдастық",
      podbor: "Таңдау",
      new_products: "Жаңа тауарлар",
      all_products: "Барлық тауарлар",
    },
    community: {
      body: `<h5><strong>penumbrarental - видео-жабдықтарды жалға беру ғана емес!</strong></h5>
        <div>&nbsp;</div>
        <p>Біз өзәра көмек көрсету мәдениеті мен қауымдастықтықты дәріптейміз.</p>
        <div>Penumbrarental-да біз:</div>
        <div>&bull; пайдалы ақпарат алмасуды және нетворкингті қолдаймыз;</div>
        <div>&bull; бірлескен жобалар жасаймыз;</div>
        <div>&bull; саладағы өзекті тақырыптарды талдаймыз;</div>
        <div>&bull; нарық көшбасшыларымен шеберлік сабақтары арқылы даму мүмкіндігін береміз;</div>
        <div>&bull; әрбір тұтынушыны дос ретінде қарсы аламыз.</div>
        <div>&nbsp;</div>
        <div><strong>penumbrarental - шығармашылық пен жасампаздыққа арналған құралдар.</strong></div>
      `,
      btn: "қоғамдас болғым келеді",
    },
    in_process: "Процессте",
    show_all: "Барлығын көру",
  },
};
