import "./basket-item.scss";
import { Link } from "react-router-dom";
import { useLayoutEffect, useRef, useState } from "react";
import { toPrice } from "../../../../utils/price";
import { BasketService } from "../../../../services/basket.service";
import { BasketProductDelete } from "./delete/basket-item-delete";
import { localeString } from "../../../../utils/localeString";
import { useTranslation } from "react-i18next";
import { ImageField } from "../../../../utils/image";
import { useEffect, useContext } from "react";
import { debounce } from "lodash";
import { BasketContext } from '../basket';
import { store } from "../../../../store/store";
import { loadBasket } from "../../../../components/pages/basket/basket.slice";


export function BasketProductItem(prop: any) {
    const { t } = useTranslation();
    const [count, setCount] = useState<number>(prop.count);
    const [shifts, setShifts] = useState<number>(prop.shifts || 1); // added shifts state here
    const [toDelete, setToDelete] = useState<boolean>(false);
    const basketService = new BasketService();
    const uuid: string | null = localStorage.getItem("user_bag_id");
    const firstUpdate = useRef(true);
    const { setLoadingPrice } = useContext(BasketContext);

    const userbagItemId = useRef(prop.id);

    useEffect(() => {
        // Update the ref whenever the prop object changes
        userbagItemId.current = prop.id;
    }, [prop]);

    useEffect(() => {
        // Update the ref whenever the prop object changes
        propIdRef.current = prop.product.id;
    }, [prop]);

    // function to handle decrease in count
    const decreaseCount = () => {
        setLoadingPrice(true)
        setCount(prevCount => {
            let newCount = prevCount > 1 ? prevCount - 1 : 0;
            if (newCount === 0) {
                setToDelete(true);
            } else {
                handleButtonClick(() => { }, newCount, shifts);
            }
            return newCount;
        });
    };


    // function to handle increase in count
    const increaseCount = () => {
        setLoadingPrice(true)
        setCount(prevCount => {
            let newCount = prevCount < prop.product.amount ? prevCount + 1 : prevCount;
            handleButtonClick(() => { }, newCount, shifts);
            return newCount;
        });
    };


    // function to handle decrease in shifts
    const decreaseShifts = () => {
        setLoadingPrice(true)
        setShifts(prevShifts => {
            let newShifts = prevShifts > 1 ? prevShifts - 1 : 1;
            handleButtonClick(() => { }, count, newShifts);
            return newShifts;
        });
    };


    // function to handle increase in shifts
    const increaseShifts = () => {
        setLoadingPrice(true)
        setShifts(prevShifts => {
            let newShifts = prevShifts < 100 ? prevShifts + 1 : prevShifts;
            handleButtonClick(() => { }, count, newShifts);
            return newShifts;
        });
    };




    /* const debouncedDispatchAndLoading = useRef(
    *     debounce((updatedBasket) => {
    *         store.dispatch(loadBasket(updatedBasket));
    *         setLoadingPrice(false);
    *     }, 4000)
    * ).current; */

    const debouncedPatch = useRef(
        debounce((count, shifts) => {
            setLoadingPrice(true); // Set loading state before starting the request
            const payload = { shifts, count, product: propIdRef.current };
            basketService
                .patchProductAndUpdate(uuid, userbagItemId.current, payload)
                .then((updatedBasket) => {
                    prop.debouncedDispatch(updatedBasket);
                })
                .catch((error) => {
                    // Handle error if needed
                    setLoadingPrice(false); // Set loading state to false on error
                })
        }, 300)
    ).current;



    const handleButtonClick = (action, updatedCount, updatedShifts) => {
        action();
        debouncedPatch(updatedCount, updatedShifts);
    };


    useLayoutEffect(() => {
        setCount(prop.count);
        setShifts(prop.shifts || 1);
    }, [prop.basket]);


    const mobile: boolean = window.innerWidth <= 1024;

    useEffect(() => {
        return () => {
            debouncedPatch.cancel();
        };
    }, []);

    const propIdRef = useRef(prop.product.id);

    useEffect(() => {
        propIdRef.current = prop.product.id;
    }, [prop.product.id]);

    return toDelete ? (
        <BasketProductDelete product={prop} onCancel={() => setToDelete(false)} />
    ) : (
        <div className="basket-item-holder animate-fade">
            {prop.product.discount ? (
                <div className="basket-item-discount">-{prop.product.discount}%</div>
            ) : (
                <></>
            )}
            {prop.product.category.slug !== "transport" &&
                prop.product.category.slug !== "personnel" ? (
                <img
                    className="basket-item-image"
                    src={ImageField(prop.product.image?.image_thumb720)}
                    alt=""
                />
            ) : (
                <></>
            )}
            {mobile ? (
                <>
                    <div className="basket-item-mobile-holder">
                        <div className="basket-item-mobile">
                            <Link
                                to={`/${prop.product.category.slug}/${prop.product.slug}`}
                                className="basket-item-info"
                            >
                                <div className="name overflow">
                                    {" "}
                                    {t(localeString(prop.product, "name"))}{" "}
                                </div>
                                <div className="category overflow">
                                    {t(localeString(prop.product.category, 'name'))}
                                </div>
                            </Link>
                            <button className="btn p-1" onClick={() => setToDelete(true)}>
                                <div className="penumbra-trash" style={{ fontSize: 14 }}></div>
                            </button>
                        </div>
                        <div className="basket-item-mobile mt-1">
                            <div className="basket-item-count">
                                <button
                                    disabled={count <= 1}
                                    className="btn icon"
                                    onClick={() => handleButtonClick(() => decreaseCount(), count - 1, shifts)}
                                >
                                    <div className="penumbra-minus" style={{ fontSize: 10 }}></div>
                                </button>
                                <div>{count}</div>
                                <button
                                    disabled={count >= prop.product.amount}
                                    className="btn icon"
                                    onClick={() => handleButtonClick(() => increaseCount(), count + 1, shifts)}
                                >
                                    <div className="penumbra-add" style={{ fontSize: 10 }}></div>
                                </button>
                            </div>
                            {/* New Shifts Section */}
                            <div className="basket-item-shifts">
                                <button
                                    disabled={shifts === 1}
                                    className="btn icon"
                                    onClick={() => handleButtonClick(() => decreaseShifts(), count, shifts - 1)}
                                >
                                    <div className="penumbra-minus" style={{ fontSize: 10 }}></div>
                                </button>
                                <div>{shifts}</div>
                                <button
                                    className="btn icon"
                                    onClick={() => handleButtonClick(() => increaseShifts(), count, shifts + 1)}
                                >
                                    <div className="penumbra-add" style={{ fontSize: 10 }}></div>
                                </button>
                            </div>
                            <div className="basket-item-price">
                                <div className="overall overflow">
                                    {toPrice(prop.product.price * count)}
                                </div>
                                {/* <div className='explanation overflow'>{count} шт x {toPrice(prop.product.price)}</div> */}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Link
                        to={`/${prop.product.category.slug}/${prop.product.slug}`}
                        className={`basket-item-info ${prop.product.category.slug === "transport" ||
                            prop.product.category.slug === "personnel"
                            ? "transport-item"
                            : ""
                            }`}
                    >
                        <div className="name overflow">
                            {" "}
                            {t(localeString(prop.product, "name"))}{" "}
                        </div>
                        <div className="category overflow">
                            {t(localeString(prop.product.category, 'name'))}
                        </div>
                    </Link>
                    <div className="basket-item-count">
                        <button
                            className="btn icon"
                            disabled={count <= 1}
                            onClick={() => handleButtonClick(() => decreaseCount(), count - 1, shifts)}
                        >
                            <div className="penumbra-minus" style={{ fontSize: 10 }}></div>
                        </button>
                        <div>{count}</div>
                        <button
                            disabled={count >= prop.product.amount}
                            className="btn icon"
                            onClick={() => handleButtonClick(() => increaseCount(), count + 1, shifts)}
                        >
                            <div className="penumbra-add" style={{ fontSize: 10 }}></div>
                        </button>
                    </div>
                    <div className="basket-item-shifts">
                        <button
                            disabled={shifts === 1}
                            className="btn icon"
                            onClick={() => handleButtonClick(() => decreaseShifts(), count, shifts - 1)}
                        >
                            <div className="penumbra-minus" style={{ fontSize: 10 }}></div>
                        </button>
                        <div>{shifts}</div>
                        <button
                            className="btn icon"
                            onClick={() => handleButtonClick(() => increaseShifts(), count, shifts + 1)}
                        >
                            <div className="penumbra-add" style={{ fontSize: 10 }}></div>
                        </button>

                    </div>
                    <div className="basket-item-price">
                        <div className="overall overflow">
                            {toPrice(prop.product.price * count)}
                        </div>
                        {/* <div className='explanation overflow'>{count} шт x {toPrice(prop.product.price)}</div> */}
                    </div>
                    <button className="btn" onClick={() => setToDelete(true)}>
                        <div className="penumbra-trash" style={{ fontSize: 14 }}></div>
                    </button>
                </>
            )}
        </div>
    );
}
