import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductState {
  product: any | null;
  relatedProducts: any[];
}

const initialState: ProductState = {
  product: null,
  relatedProducts: [],
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    startLoadingProduct: () => initialState,
    loadProduct: (state, { payload: product }: PayloadAction<any>) => {
      state.product = product;
    },
    loadRelatedProducts: (
      state,
      { payload: relatedProducts }: PayloadAction<any[]>
    ) => {
      state.relatedProducts = relatedProducts;
    },
  },
});

export const { startLoadingProduct, loadProduct, loadRelatedProducts } =
  slice.actions;

export default slice.reducer;
