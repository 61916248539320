import { useParams, Link } from "react-router-dom";
import { store } from "../../../store/store";
import { useStore } from "../../../store/storeHooks";
import {
    loadProduct,
    loadRelatedProducts,
    startLoadingProduct,
} from "./product.slice";
import { ProductService } from "../../../services/products.service";
import { useEffect, useState } from "react";
import { useLocationChange } from "../../../utils/locationChange";
import { changeCategory } from "../../aside/aside.slice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Swiper as SW } from "swiper";
import { toPrice } from "../../../utils/price";
import Modal from "react-bootstrap/Modal";
import Vimeo from "@u-wave/react-vimeo";
import { BasketService } from "../../../services/basket.service";
import { ProductRelatedList } from "./product-related/product-related";
import { ProductDetailSpecs } from "./product-specs/product-specs";
import { Keyboard, Thumbs, Mousewheel, Pagination } from "swiper";
import { localeString } from "../../../utils/localeString";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../common/spinner/spinner";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/scss";
import "./product.scss";
import getImage from "../../../utils/image";
import { loadBasket } from "../../../components/pages/basket/basket.slice";
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";


function ImagesModal(props: { images: any[]; show: any; onHide: () => void }) {
    const images: JSX.Element[] | undefined = props.images
        .slice()
        .reverse()
        .map((image: any, key: number) => (
            <SwiperSlide key={key}>
                <img className="modal-photo" src={getImage(image?.image)} alt="" />
            </SwiperSlide>
        ));

    /* const images: JSX.Element[] | undefined = props.images.map((image: any, key: number) =>
     *     <SwiperSlide key={key}>
     *         <img className='modal-photo' src={getImage(image?.image)} alt="" />
     *     </SwiperSlide>
     * ); */
    return (
        <Modal
            {...props}
            contentClassName="modal-custom"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <button
                className="btn penumbra-add modal-custom-remove"
                onClick={() => props.onHide()}
            ></button>
            <Swiper
                className="carousel-swiper"
                slidesPerView={1}
                keyboard={{
                    enabled: true,
                }}
                loop={props.images.length > 1 ? true : false}
                mousewheel={{
                    forceToAxis: true,
                    sensitivity: 0.7,
                }}
                navigation={true}
                modules={[Keyboard, Mousewheel, Navigation]}
                spaceBetween={16}
            >
                {images}
            </Swiper>
        </Modal>
    );
}

function VideoModal(props: any) {
    return (
        <Modal
            {...props}
            contentClassName="modal-custom"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {props.product.instruction_video ? (
                <>
                    <button
                        className="btn penumbra-add modal-custom-remove"
                        onClick={() => props.onHide()}
                    ></button>
                    <Vimeo
                        className="d-flex align-items-center justify-content-center"
                        video={props.product.instruction_video}
                        autoplay
                    />
                </>
            ) : (
                <></>
            )}
        </Modal>
    );
}

export function ProductPage(props: { locationChange: () => void }) {
    const { t } = useTranslation();
    const { slug } = useParams<{ slug: string }>();
    const { productSlug } = useParams<{ productSlug: string }>();
    const [modalShow, setModalShow] = useState(false);
    const [count, setCount] = useState<number>(1);
    const [section, setSection] = useState<number>(1);
    const { basket } = useStore(({ basket }) => ({ basket }));
    const [thumbsSwiper, setThumbsSwiper] = useState<SW | undefined>(undefined);
    const [index, setIndex] = useState(0);
    const [isAddingToBasket, setIsAddingToBasket] = useState(false);

    const [photoModal, setPhotoModal] = useState(false);

    useLocationChange((location: any) => {
        props.locationChange();
    });



    const productService = new ProductService();
    const basketService = new BasketService();

    const { product } = useStore(({ product }) => ({ product }));


    useEffect(() => {
        if (product.product !== null) {
            ReactGA.event({
                category: 'Product',
                action: 'view_item',
                label: product.product?.name_en,
                value: parseInt(product.product?.price)
            });
        }
    }, []);



    const uuid: string | null = localStorage.getItem("user_bag_id");

    useEffect(() => {
        onLoad(productSlug);
        store.dispatch(changeCategory(slug));
        props.locationChange();
    }, [productSlug]);

    async function onLoad(slug: string | undefined) {
        store.dispatch(startLoadingProduct());
        const res = await productService.getProduct(slug);
        store.dispatch(loadProduct(res));
        const releted = await productService.getRelatedProduct(slug);
        if (releted.length === 0) {
            setSection(2);
        }
        store.dispatch(loadRelatedProducts(releted));
    }

    const images: JSX.Element[] | undefined = product?.product?.images
        .slice()
        .reverse()
        .map((image: any, key: number) => (
            <SwiperSlide
                key={key}
                className={index === key ? "thumb-active" : ""}
                onClick={() => {
                    thumbsSwiper?.slideTo(key);
                    setIndex(key);
                }}
            >
                <div className="image-holder">
                    <img
                        src={getImage(image?.image_thumb360 || image?.image_thumb720 || "")}
                        alt=""
                    />
                </div>
            </SwiperSlide>
        ));

    const images_main: JSX.Element[] | undefined = product?.product?.images
        .slice()
        .reverse()
        .map((image: any, key: number) => (
            <SwiperSlide key={key} onClick={() => setPhotoModal(true)}>
                <div className="image-holder">
                    <img src={getImage(image?.image)} alt="" />
                </div>
            </SwiperSlide>
        ));

    async function sendBasketNew(prod: any | null) {
        setIsAddingToBasket(true); // set to true when starting the request

        const payload = {
            shifts: 1,
            count: count,
            product: prod?.id,
        };

        basketService
            .postProductAndFetchUpdatedBasket(uuid, payload)
            .then((updatedBasket) => {
                ReactGA.event({
                    category: 'Basket',
                    action: 'Added Product',
                    label: prod?.name_en,
                    value: parseInt(prod?.price)
                });
                store.dispatch(loadBasket(updatedBasket));
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    localStorage.removeItem("user_bag_id");
                    basketService.init().then((res) => {
                        localStorage.setItem("user_bag_id", res.id);
                        basketService.getBasket(res.id).then((res) => {
                            store.dispatch(loadBasket(res));
                        });
                    });
                }
            })
            .finally(() => {
                setIsAddingToBasket(false); // set to false when done, regardless of success or failure
            });
    }

    async function sendBasket(prod: any | null) {
        const payload = {
            shifts: 1,
            count,
            product: prod?.id,
        };
        basketService.postProduct(uuid, payload).then(() => {
            basketService._getBasket(uuid);
        });
    }

    const setList: JSX.Element[] | undefined =
        product?.product?.set_products?.map((item, key) => (
            <li key={key}>
                {item.active ? (
                    <Link to={`/${item.category.slug}/${item.slug}`}>
                        {t(localeString(item, "name"))}
                    </Link>
                ) : (
                    <span>{t(localeString(item, "name"))}</span>
                )}
            </li>
        ));

    /* const serviceList: string[] | undefined = product?.product?.services.map(item => item.service.name_ru) */
    const serviceList: string[] | undefined = product?.product?.services.map(
        (item) => localeString(item.service, "name")
    );

    const mobile: boolean = window.innerWidth >= 1024;

    return product.product ? (
        <>
            <Helmet>
                <title>{product.product.name_ru}</title>
                <meta property="og:title" content={product.product.name_ru} />
                <meta property="og:image" content={product.product.images[0]?.image} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="product.item" />
            </Helmet>
            <div className="product animation-opacity">
                <div className="product-images">
                    <Swiper
                        className="virtual-swiper"
                        direction={"vertical"}
                        modules={[Thumbs]}
                        loop={product.product.images.length > 5 ? true : false}
                        spaceBetween={16}
                        slidesPerView={5}
                        watchSlidesProgress={true}
                    >
                        {images}
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        className="main-swiper"
                        modules={[Thumbs, Mousewheel, Pagination]}
                        pagination={window.innerWidth < 1024}
                        slidesPerView={1}
                        loop={false}
                        spaceBetween={16}
                        mousewheel={{
                            forceToAxis: true,
                            sensitivity: 0.7,
                        }}
                        onSlideChange={(s) => setIndex(s.activeIndex)}
                    >
                        {images_main}
                    </Swiper>
                </div>
                <div className="product-info">
                    <h1>{t(localeString(product.product, "name"))} </h1>
                    <div className="d-flex gap-3 articule align-items-center">
                        <p style={{ marginBottom: 0 }}>
                            {t("product.articule", { articule: product.product.articule })}
                        </p>
                        {product.product.services.length > 0 ? (
                            <div className="d-flex gap-2 align-items-center product-info-required">
                                <p style={{ marginBottom: 0 }}>
                                    ❕{t("product.required_service")}{" "}
                                    {serviceList ? serviceList.join(", ") : ""}
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div
                        className="d-flex align-items-end justify-content-center"
                        style={{ width: "max-content" }}
                    >
                        <h4>{toPrice(product.product.price)}</h4>
                        {product.product.discount ? (
                            <div className="d-flex align-items-end">
                                <h5 className="line-througth">
                                    {toPrice(product.product.daily_price)}
                                </h5>
                                <span className="product-discount">
                                    –{parseInt(product.product.discount)}%
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="card-add mb-3">

                        <div className='count'>

                            <button
                                disabled={basket.products.map(obj => obj.product.id).includes(product.product.id) || count === 1}
                                className='btn icon'
                                onClick={() => {
                                    count > 1 ? setCount(count - 1) : setCount(count)
                                }}>
                                <div className='penumbra-minus' style={{ fontSize: 12 }}></div>
                            </button>

                            <div className='count-amount'>{count}</div>

                            <button
                                disabled={basket.products.map(obj => obj.product.id).includes(product.product.id) || product.product.amount <= count}
                                className='btn icon'
                                onClick={() => {
                                    count < product!.product!.amount ? setCount(count + 1) : setCount(count)
                                }}>
                                <div className='penumbra-add' style={{ fontSize: 12 }}></div>
                            </button>

                        </div>
                        {mobile ? (
                            <>
                                {basket.products
                                    .map((obj) => obj.product.id)
                                    .includes(product.product.id) ? (
                                    <Link to={"/🛒"} className="btn bordered-btn btn-black">
                                        <span className="overflow">{t("product.to_card")}</span>
                                    </Link>
                                ) : (
                                    <button
                                        className="btn bordered-btn btn-black add"
                                        disabled={isAddingToBasket}
                                        onClick={() => sendBasketNew(product.product)}
                                    >
                                        {t("product.add_card")}
                                    </button>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    {product.product.instruction_video ? (
                        <>
                            <button
                                className="btn btn-gray mb-3"
                                onClick={() => setModalShow(true)}
                            >
                                <div
                                    className="penumbra-play"
                                    style={{ fontSize: 12, marginRight: 8 }}
                                ></div>
                                <span>{t("product.instruction")}</span>
                            </button>

                            <VideoModal
                                show={modalShow}
                                product={product.product}
                                onHide={() => setModalShow(false)}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    {product.product.set_products &&
                        product.product.set_products.length > 0 ? (
                        <div className="description overflow-7">
                            <div>{t("product.set_items")}</div>
                            <div className="description-list">{setList}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {product.product.type === 0 &&
                        localeString(product.product, "description") ? (
                        <>
                            <div
                                className="description overflow-7"
                                dangerouslySetInnerHTML={{
                                    __html: t(localeString(product.product, "description")),
                                }}
                            ></div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div className="sections my-3">
                {product.relatedProducts.length > 0 ? (
                    <button
                        className={`btn btn-black ${section === 1 ? "active" : ""}`}
                        onClick={() => setSection(1)}
                    >
                        {t("product.related")}
                    </button>
                ) : (
                    <></>
                )}
            </div>
            {section === 1 && product.relatedProducts.length > 0 ? (
                <ProductRelatedList
                    product={product.product}
                    productService={productService}
                />
            ) : (
                <></>
            )}
            {section === 2 && product.product.specs.length > 0 ? (
                <ProductDetailSpecs product={product.product} />
            ) : (
                <></>
            )}
            {basket.products
                .map((obj) => obj.product.id)
                .includes(product.product.id) ? (
                <Link to={"/🛒"} className="btn add-card btn-white-outline">
                    <span className="overflow">{t("product.to_card")}</span>
                </Link>
            ) : (
                <button
                    className="btn add-card"
                    disabled={isAddingToBasket}
                    onClick={() => sendBasketNew(product.product)}
                >
                    {t("product.add_card")}
                </button>
            )}
            <ImagesModal
                show={photoModal}
                images={product.product.images}
                onHide={() => setPhotoModal(false)}
            />
        </>
    ) : (
        <Spinner color="#fff" />
    );
}
