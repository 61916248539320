import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  id: "",
  total_price: 0,
  products: [],
  services: [],
  delivery: false,
  delivery_price: 0,
  delivery_back: false,
  delivery_back_price: 0,
};

const slice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    startLoadingBasket: () => initialState,
    loadEmptyBasket: (state, { payload: uuid }: PayloadAction<string>) => {
      state.id = uuid;
      state.products = [];
      state.services = [];
      state.total_price = 0;
    },
    loadBasket: (state, { payload: basket }: PayloadAction<any>) => {
      state.id = basket.id;
      state.products = basket.products;
      state.coupon = basket.coupon;
      state.discount = basket.discount;
      state.services = basket.services;
      state.total_price = basket.total_price;
      state.services_price = basket.services_price;
      state.products_price = basket.products_price;
      state.delivery = basket.delivery;
      state.delivery_price = basket.delivery_price;
      state.delivery_back = basket.delivery_back;
      state.delivery_back_price = basket.delivery_back_price;
    },
  },
});

export const { startLoadingBasket, loadEmptyBasket, loadBasket } =
  slice.actions;

export default slice.reducer;
