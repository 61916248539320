import { Action, configureStore } from "@reduxjs/toolkit";
import category from "./../components/aside/aside.slice";
import products from "./../components/pages/product-list/product-list.slice";
import article_list from "./../components/pages/article-list/article-list.slice";
import article from "./../components/pages/article/article.slice";
import categoryProducts from "./../components/pages/category-product-list/category-product-list.slice";
import product from "./../components/pages/product/product.slice";
import basket from "./../components/pages/basket/basket.slice";

const middlewareConfiguration = { serializableCheck: false };

export const store = configureStore({
  reducer: {
    category,
    products,
    categoryProducts,
    product,
    basket,
    article_list,
    article,
  },
  devTools: {
    name: "Penumbra Rental",
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(middlewareConfiguration),
});
export type State = ReturnType<typeof store.getState>;

export function dispatchOnCall(action: Action) {
  return () => store.dispatch(action);
}
