export const basket = {
  to_basket:
    "view cart \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0 \u00A0 ⟶ \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0\u00A0 view cart \u00A0 \u00A0 ⟶ \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0 \u00A0 ⟶ \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0 \u00A0view cart \u00A0 \u00A0 \u00A0 ⟶\u00A0 ",
  clear_basket: "Delete all",
  helm: "Cart",
  total_price: "↳",
  discount: "Discount",
  delivery: "Delivery:",
  download_xlsx: "Download .xlsx ↓",
  duriation: "Rental period:",
  rent_dates: "from {{dateStart}} to {{dateEnd}}",
  first_time: "I am renting the equipment for the first time",
  deliver_back: "There and back",
  products_price: "Devices",
  services_price: "Personnel & transport",
  coupon_applied: "Coupon applied",
  coupon_input: "Coupon?",
  same_address: "One address",
  header: {
    name: "Name",
    count: "Quantity",
    shifts: "Shifts",
    price: "Price",
  },
  form: {
    header: "Fill in the information",
    name_placeholder: "Name",
    address_placeholder: "Address",
    address_return_placeholder: "Return address",
    address_message: "get acquainted with the delivery area",
    pickup_option: "Self-pickup",
    delivery_option: "Delivery",
    delivery_map_location:
      "We deliver within the square of Sain - Al-Farabi - VOAD - Raiymbek Batyr",
    phone_placeholder: "Phone",
    comment_placeholder: "Comments",
    rent_period: "Rental period",
    rent_start: "Start",
    rent_finish: "End",
    date_placeholder: "dd.mm.yyyy",
  },
  empty_list_error:
    "The cart is empty!<br />Go back to the catalog and select the required equipment",
  empty_list_error_back: "Return to the main page",
  required_for: "Required",
  order: "Book",
  empty_list: "The cart is empty",
  clear_all: "everything in the basket",
  deleting: {
    message: "You are deleting ",
    cancel: "cancel",
    delete: "delete",
    clear_all: "delete",
  },
  success_message: "The equipment has been successfully booked!",
  success_message_10min: "Our manager will contact you soon",
  success_message_hint:
    "if the waiting time takes longer, we will give a 3% discount on the next rental",
};
