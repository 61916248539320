import { footer } from "./footer.locale";
import { basket } from "./basket.locale";
import { product } from "./product.locale";
import { grant } from "./grant.locale";

export const i18n_en = {
  translation: {
    _locale: "",
    basket,
    product,
    footer,
    grant,
    main_screen: "Main",
    categories_screen: "All categories",
    basket_screen: "Basket",
    faq: "Questions and answers",
    request_call: "Call",
    have_questions: "Do you have questions?<br>Please contact us",
    main: {
      payment: "Payment",
      community: "Community",
      podbor: "Selection",
      new_products: "New products",
      all_products: "All devices",
    },
    in_process: "In process",
    show_all: "Show all",
  },
};
