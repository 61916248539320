export const grant = {
  name: "Аты-жөнінңізді енгізіңіз",
  name_placeholder: "Аты-жөнінңіз",
  phone: "Телефон нөмірін енгізіңіз",
  phone_placeholder: "+ 7 (777) 999 99 99",
  instagram: "Instagram парақшаңызға сілтеме",
  instagram_placeholder: "https://www.instagram.com/penumbrarental",
  portfolio: "Портфолиоға сілтеме",
  portfolio_placeholder: "https://vimeo.com",
  description: "Жобаның сипаттамасы",
  description_placeholder: "Сіз не түсіруді жоспарлап отырсыз?",
  file: "Тритментпен файлды жүктеңіз",
  file_placeholder: "Файлды жүктеу",
  date: "Түсірілім күнін белгілеңіз",
  date_placeholder: "2023-01-01",
  comment: "Пікір",
  comment_placeholder: "",
  policy:
    "Мен құпиялылық саясатымен келісемін және жеке деректерімді өңдеуге келісім беремін",
  submit: "Өтінішті беру",
  url_error: "Жарамды сілтеме енгізіңіз",
  date_error: "Дұрыс күнді енгізіңіз",
  success_message: "Сіздің өтінішіңіз қабылданды",
  error_header: "Формада қателер табылды",
  error_message: "Қате формаларды дұрыстаңыз",
};
