import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BasketService } from "../../../../services/basket.service";

const basketService = new BasketService();

const CouponForm = ({ uuid, basket }) => {
  const [couponCode, setCouponCode] = useState("");
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    setCouponCode(event.target.value);
    setIsError(false); // Reset the error state when the input changes
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    basketService
      .applyCoupon(uuid, { coupon_code: couponCode })
      .then((res) => {
        basketService._getBasket(uuid);
        setIsError(false); // No error so reset the error state
      })
      .catch((err) => {
        setIsError(true); // Set the error state to true
      });
  };

  return (
    <div className="coupon-holder">
      {basket.coupon ? (
        <div>
          <p>
            {" "}
            {t("basket.coupon_applied")}: {basket.coupon.code}{" "}
          </p>
        </div>
      ) : (
        <form
          className="coupon-holder search-holder search-border"
          onSubmit={handleSubmit}
        >
          <input
            className={`search-input ${isError ? "input-error" : ""}`}
            type="text"
            value={couponCode}
            onChange={handleInputChange}
            placeholder={t("basket.coupon_input")}
          />
          {couponCode && (
            <button className="btn" type="submit">
              →
            </button>
          )}
        </form>
      )}
    </div>
  );
};

export default CouponForm;
