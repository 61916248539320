import { createSlice } from "@reduxjs/toolkit";

export interface CategoriesState {
  categories: any[];
  selectedCategory: string | undefined;
}

const initialState: CategoriesState = {
  categories: [],
  selectedCategory: undefined,
};

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    startLoadingCategories: () => initialState,
    loadCategories: (state, { payload: categories }) => {
      state.categories = categories;
    },
    changeCategory: (state, { payload: slug }) => {
      state.selectedCategory = slug;
    },
  },
});

export const { startLoadingCategories, loadCategories, changeCategory } =
  slice.actions;

export default slice.reducer;
