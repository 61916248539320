import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMPTY_LIST, ListModel } from "../../../models/common";

export interface ProductListState {
  products: ListModel<any>;
  loaded: boolean;
}

const initialState: ProductListState = {
  products: EMPTY_LIST,
  loaded: false,
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    startLoadingProducts: () => initialState,
    loadProducts: (
      state,
      { payload: products }: PayloadAction<ListModel<any>>
    ) => {
      state.products = products;
      state.loaded = true;
    },
  },
});

export const { startLoadingProducts, loadProducts } = slice.actions;

export default slice.reducer;
