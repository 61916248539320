export const product = {
    empty: "Бос",
    add_card: "Қосу",
    to_card: "Себетте көрсету",
    searching: "іздеу...",
    articule: "{{articule}}",
    search_empty: "Ештеңе табылған жоқ",
    required_service: "Міндетті сервис: ",
    instruction: "Бейне нұсқау",
    set: "жинақ",
    set_items: "Жинаққа не кіреді:",
    related: "Осы өніммен бірге алады",
    specs: "Cипаттамалары",
    specs_header: "Негізгі сипаттамалары",
};
