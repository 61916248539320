export const product = {
  empty: "Пусто",
  add_card: "Добавить",
  to_card: "Уже в корзине",
  searching: "Поиск...",
  search_empty: "Ничего не найдено",
  articule: "{{articule}}",
  required_service: "Обязательно: ",
  instruction: "Видеоинструкция",
  set: "Cет",
  set_items: "Сет состоит из:",
  related: "Часто арендуют вместе",
  specs: "Xарактеристики",
  specs_header: "Основные характеристики",
};
