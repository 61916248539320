import "./product-related.scss";
import { useState } from "react";
import { ProductService } from "../../../../services/products.service";
import { useStore } from "../../../../store/storeHooks";
import { ProductItem } from "../../../product-item/product-item";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css/navigation";
import { Swiper as SwiperType } from "swiper/types";
import { Mousewheel, Keyboard } from "swiper";

export function ProductRelatedList(prop: {
  product: any;
  productService: ProductService;
}) {
  const { product } = useStore(({ product }) => ({ product }));
  const relatedProducts = product.relatedProducts;
  const [swiper, setSwiper] = useState<SwiperType>();

  const relatedList: JSX.Element[] = relatedProducts.map((item, key) => (
    <SwiperSlide
      key={key}
      style={{ width: window.innerWidth >= 1024 ? 265 : "100%", height: 415 }}
    >
      <ProductItem product={item} />
    </SwiperSlide>
  ));

  return relatedProducts.length > 0 ? (
    <div className="d-flex aligh-items-center align-content-center related-holder">
      <button
        disabled={!swiper?.allowSlidePrev}
        className="btn navigation-btn penumbra-chevron-left"
        onClick={() => swiper?.slidePrev()}
      >
        {" "}
      </button>
      <Swiper
        className="flex-fill"
        direction={"horizontal"}
        spaceBetween={12}
        slidesPerView={window.innerWidth <= 1024 ? 2 : "auto"}
        onSwiper={setSwiper}
        keyboard={{
          enabled: true,
        }}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 0.7,
        }}
        loop={window.innerWidth <= 1024 ? true : false}
        centeredSlides={window.innerWidth <= 1024 ? true : false}
        modules={[Keyboard, Mousewheel]}
      >
        {relatedList}
      </Swiper>
      <button
        disabled={!swiper?.allowSlideNext}
        className="btn navigation-btn penumbra-chevron-right"
        onClick={() => swiper?.slideNext()}
      >
        {" "}
      </button>
    </div>
  ) : (
    <></>
  );
}
