import axios from "axios";

export class ProductService {

    public async equipmentRequest(equipment_request: string): Promise<any> {
        const res = await axios.post("v1/equipment-request/", { equipment_request });
        return res.data;
    }

    public async messageRequest(message_request: string): Promise<any> {
        const res = await axios.post("v1/message-request/", { message_request });
        return res.data;
    }



    public async addProductsFromText(userbagId, text): Promise<any> {
        const res = await axios.post(`v1/bag2/${userbagId}/add_products_text/`, { text: text });
        return res.data;
    }

    public async categoryProductList(): Promise<any[]> {
        const res = await axios.get("/v1/products/all/");
        return res.data;
    }

    public async newProductList(): Promise<any[]> {
        const res = await axios.get("/v1/products/new/");
        return res.data;
    }

    public async listProducts(params?: any): Promise<any> {
        const res = await axios.get("/v1/products/", { params });
        return res.data;
    }

    public async getProduct(slug: any, params?: any): Promise<any> {
        const res = await axios.get(`/v1/products/${slug}/`, { params });
        return res.data;
    }

    public async getRelatedProduct(slug: any, params?: any): Promise<any[]> {
        const res = await axios.get(`/v1/products/${slug}/related/`, { params });
        return res.data;
    }
}
