/* import "./date-picker.scss"; */
import "../../../../main.css";
import { useState, useEffect } from "react";
import moment from "moment";
import Modal from "react-bootstrap/esm/Modal";
import { DateRange, Range as rn } from "react-date-range";
import * as loc from "date-fns/locale";
import i18n from "../../../../i18n/i18n";
import { useTranslation } from "react-i18next";
import "moment/locale/ru";
import "moment/locale/kk";

function DatePickerModal({ onApply, startDate, endDate, ...props }: any) {
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  const { t, i18n: i18nInstance } = useTranslation();

  useEffect(() => {
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }, [startDate, endDate]);

  useEffect(() => {
    moment.locale(i18nInstance.language);
  }, [i18nInstance.language]);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([(item as any).selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
            color={"#000"}
            displayMode="dateRange"
            locale={
              i18n.language === "ru"
                ? loc.ru
                : i18n.language === "kk"
                ? loc.kk
                : loc.enUS
            }
            minDate={new Date()}
            rangeColors={["#000", "#000", "#000"]}
          />
          <button
            disabled={state[0].startDate === state[0].endDate}
            className="btn btn-black"
            onClick={() => onApply(state[0])}
          >
            ⟶
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function DatePicker(prop: {
  onChange: (dateStart: Date, dateEnd: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);

  // Update state when props change
  useEffect(() => {
    setDateStart(prop.startDate);
    setDateEnd(prop.endDate);
  }, [prop.startDate, prop.endDate]);

  const { t, i18n: i18nInstance } = useTranslation();

  useEffect(() => {
    moment.locale(i18nInstance.language);
  }, [i18nInstance.language]);

  const onApply = (range: rn) => {
    setModalShow(false);
    const dates = range;
    setDateStart(dates.startDate!);
    setDateEnd(dates.endDate!);
    prop.onChange(dates.startDate!, dates.endDate!);
  };

  return (
    <div className="d-flex">
      <button
        className="btn form-input calendar margin-left p-0 flex-fill"
        style={{ height: 33 }}
        onClick={() => setModalShow(true)}
      >
        {dateStart
          ? moment(dateStart).locale(i18nInstance.language).format("DD MMMM")
          : t("basket.form.date_placeholder")}
      </button>
      <button
        className="btn form-input calendar margin-right p-0 flex-fill"
        style={{ height: 33 }}
        onClick={() => setModalShow(true)}
      >
        {dateEnd
          ? moment(dateEnd).locale(i18nInstance.language).format("DD MMMM")
          : t("basket.form.date_placeholder")}
      </button>
      <input
        className="d-none form-input calendar margin-left"
        type="date"
        disabled
        value={dateStart ? moment(dateStart).format("yyyy-MM-DD") : ""}
      />
      <input
        className="d-none form-input calendar margin-right"
        type="date"
        disabled
        value={dateEnd ? moment(dateEnd).format("yyyy-MM-DD") : ""}
      />
      <DatePickerModal
        show={modalShow}
        onApply={onApply}
        onHide={() => setModalShow(false)}
        startDate={dateStart}
        endDate={dateEnd}
      />
    </div>
  );
}
