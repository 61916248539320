import { BasketService } from "../../../services/basket.service";
import { useLocationChange } from "../../../utils/locationChange";
import { FormEvent, useEffect, useRef, useState, createContext, useContext } from "react";
import { useStore } from "../../../store/storeHooks";
import { toPrice } from "../../../utils/price";
import { BasketProductItem } from "./basket-item/basket-item";
import { BasketClearDelete } from "./basket-clear-delete/basket-clear-delete";
import InputMask from "react-input-mask";
import moment from "moment";
import ThankPageModal from "./thank-page/thank-page";
import { BasketProductServiceItem } from "./basket-service-item/basket-service-item";
/* import kz_flag from "./kz_flag.png" */
/* import unknown_flag from "./unknown_flag.png" */
import DatePicker from "./date-picker/date-picker";
import CouponForm from "./coupon-form/coupon-form";
import TimePicker, { initialTime } from "./time-picker/time-picker";
import { useTranslation } from "react-i18next";
import "./basket.scss";
import "./form-switcher.scss";
import { useNavigate } from "react-router";
import { store } from "../../../store/store";
import { loadBasket, loadEmptyBasket } from "./basket.slice";
import DeliveryMapModal from "./delivery-map/delivery-map";
import NoProductsModal from "./no-products/no-products";
import { Helmet } from 'react-helmet';
import { Spinner } from "../../common/spinner/spinner";
import { debounce } from "lodash";
import ReactGA from "react-ga4";




interface OrderForm {
    name: string;
  phone: string;
  address?: string;
  address_return?: string;
  first_time_order: boolean;
  start_time: string;
  start_time_date: Date;
  end_time: string;
  end_time_date: Date;
  comment: string;
}

interface OrderFormStorage {
  name: string;
  phone: string;
  address?: string;
  address_return?: string;
  first_time_order: boolean;
  start_time: string;
  start_time_date: string | null; // these fields can now be strings or null
  end_time: string;
  end_time_date: string | null; // these fields can now be strings or null
  comment: string;
}

function getDaysBetween(start, end) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(Math.abs((start - end) / oneDay));
  return diffDays;
}

function getTime(date: Date, time: string): Date {
  if (!date || !time) {
    return null;
  }
  const [hours, minutes] = time.split(":");
  date.setHours(+hours);
  date.setMinutes(+minutes);
  return date;
}

export const BasketContext = createContext({
    loadingPrice: false,
    setLoadingPrice: (loading) => {},
});

export function BasketPage(props: { locationChange: () => void }) {
    useLocationChange((location: any) => {
    props.locationChange();
  });

    const [loadingPrice, setLoadingPrice] = useState(false);
    const { t } = useTranslation();
    const [height, setHeight] = useState(0);
  const [childHeight, setChildHeight] = useState(0);
  const listRef = useRef<any>(null);
  const childRef = useRef<any>(null);
    /* const { loadingPrice } = useContext(BasketContext); */

    const { basket } = useStore(({ basket }) => ({ basket }));
    const [numDays, setNumDays] = useState(1);
  const basketService = new BasketService();
  const uuid: string | null = localStorage.getItem("user_bag_id");
  const navigate = useNavigate();

  // lang toggler
  const [collapsed, setCollapsed] = useState(false);
  const [county, setCounty] = useState<"kk" | "other">("kk");
  const [mask, setMask] = useState("+7 (799) 999 99 99"); // kz
  const [sameAddress, setSameAddress] = useState(false);

  useEffect(() => {
    if (county === "kk") {
      setMask("+7 (799) 999 99 99");
    } else if (county === "other") {
      setMask("+9 (999) 999 99 99");
    }
    setForm({ ...form, phone: "" });
  }, [county]);

  // form
  const [form, setForm] = useState<OrderForm>({
    name: "",
    phone: "",
    first_time_order: false,
    start_time: initialTime(),
    start_time_date: null,
    end_time: initialTime(),
    end_time_date: null,
    comment: "",
  });

  useEffect(() => {
    // Load form state from localStorage when component mounts
    const storedForm = localStorage.getItem("form");
    if (storedForm) {
      const parsedForm: OrderForm = JSON.parse(storedForm);
      parsedForm.start_time_date = parsedForm.start_time_date
        ? new Date(parsedForm.start_time_date)
        : null;
      parsedForm.end_time_date = parsedForm.end_time_date
        ? new Date(parsedForm.end_time_date)
        : null;
      setForm(parsedForm);
    }
  }, []);

  useEffect(() => {
    // Save form state to localStorage whenever it changes
    const toStoreForm: OrderFormStorage = {
      ...form,
      start_time_date: form.start_time_date
        ? form.start_time_date.toISOString()
        : null,
      end_time_date: form.end_time_date
        ? form.end_time_date.toISOString()
        : null,
    };
    localStorage.setItem("form", JSON.stringify(toStoreForm));
  }, [form]);

  // delivery
  const [delivery, setDelivery] = useState<boolean>(false);
  const [mapPage, setMapPage] = useState<boolean>(false);

  useEffect(() => {
    if (basket.id) {
      setDelivery(basket.delivery);
    }
  }, [basket]);

  const [thankPage, setThankPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setHeight(listRef.current.clientHeight);
    setChildHeight(childRef.current.clientHeight);
  }, [listRef, childRef, basket]);

  const [clearBasket, setClearBasket] = useState<boolean>(false);

  const clear = () => {
    setClearBasket(true);
  };

  const handleSubmit = async (event: FormEvent) => {
    if (
      !(form.start_time_date instanceof Date) ||
      !(form.end_time_date instanceof Date)
    ) {
      console.error(
        "start_time_date and end_time_date should be Date objects."
      );
      return;
    }
    event.preventDefault();
    setIsSubmitting(true);
    let payload = {
      name: form.name,
      comment: form.comment,
      phone: form.phone,
      first_time_order: form.first_time_order,
      start_time: getTime(form.start_time_date, form.start_time).toISOString(),
      end_time: getTime(form.end_time_date, form.end_time).toISOString(),
    };
    if (delivery) {
      payload = { ...payload, ...{ address: form.address } };
      if (basket.delivery_back)
        payload = { ...payload, ...{ address_return: form.address } };
      if (!sameAddress)
        payload = { ...payload, ...{ address_return: form.address_return } };
    }
    basketService.createOrder(uuid, payload).then(() => {
      setThankPage(true);
      ReactGA.event({
          category: 'Ecommerce',
          action: 'OrderPlaced',
          value: basket.total_price // Assuming `basket.total` holds the total value of the order
      });
      ReactGA.gtag('event', 'purchase', {
          'transaction_id': basket.id, // Assuming `id` is a unique identifier for the transaction
          'affiliation': 'Your Store', // Name of the store or affiliation
          'value': basket.total_price, // Total revenue for the transaction, including tax and shipping
          'currency': 'KZT', // Currency code, assuming you're using Kazakhstani tenge
          'items': basket.products.map(item => ({ // List of products in the order
              'item_id': item.product.id, // ID of the product
              'item_name': item.product.name_en, // Name of the product
              'item_category': item.product.category.name_en, // Category of the product
              'price': item.product.price, // Price of the product
              'quantity': item.count, // Quantity of the product
              'shifts': item.shifts,
          }))
      });
        basketService
            .init({ previous_order: uuid })
            .then((res) => {
          localStorage.setItem("user_bag_id", res.id);
          store.dispatch(loadEmptyBasket(res.id));
          resetFields();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  const onDelivery = (status: boolean) => {
    setDelivery(status);
    if (status === false) {
      setForm({ ...form, address: "", address_return: "" });
      setDelivery(false);
      setSameAddress(false);
      basketService
        .updateDelivery(uuid, { delivery_back: false })
        .then(() => {});
    }
    basketService.updateDelivery(uuid, { delivery: status }).then(() => {
      basketService
        .getBasket(uuid)
        .then((res) => store.dispatch(loadBasket(res)));
    });
  };

  const onDeliveryBack = (status: boolean) => {
    basketService.updateDelivery(uuid, { delivery_back: status }).then(() => {
      basketService
        .getBasket(uuid)
        .then((res) => store.dispatch(loadBasket(res)));
      setSameAddress(false);
    });
  };

  function resetFields() {
    setForm({
      name: "",
      phone: "",
      address: null,
      address_return: null,
      first_time_order: false,
      start_time: initialTime(),
      start_time_date: null,
      end_time: initialTime(),
      end_time_date: null,
      comment: "",
    });
  }

  const days = (d1: Date | undefined, d2: Date | undefined): number => {
    if (!d1 && !d2) {
      return 1;
    }
    var t2 = d2!.getTime();
    var t1 = d1!.getTime();

    if (t2 - t1 === 0) {
      return 1;
    }

    return Math.ceil((t2 - t1) / (24 * 3600 * 1000));
  };

  const haveTime =
    form.start_time &&
    form.end_time &&
    form.start_time_date &&
    form.end_time_date;

  const validDelivery = () => {
    if (basket.delivery_back && !sameAddress) {
      return !!form.address && !!form.address_return;
    }
    if (basket.delivery_back && sameAddress) {
      return !!form.address;
    }
    if (delivery) {
      return !!form.address;
    }
    return true;
  };

  const valid =
    haveTime &&
    form.name &&
    form.phone &&
    !form.phone.includes("_") &&
    validDelivery() &&
    basket.products.length > 0;

  const debouncedDispatch = useRef(
    debounce((updatedBasket : any) => {
        store.dispatch(loadBasket(updatedBasket));
        setLoadingPrice(false);
    }, 1800 + Math.random() * 600 - 300)
  ).current;

    useEffect(() => {
    return () => {
      debouncedDispatch.cancel();
      };
    }, []);


    const productList: JSX.Element[] = [...basket.products]
        .sort((a, b) => {
            const aIsSpecialCategory = ["transport", "personnel"].includes(
                a.product.category.slug
            );
      const bIsSpecialCategory = ["transport", "personnel"].includes(
        b.product.category.slug
      );

      if (aIsSpecialCategory && !bIsSpecialCategory) {
        return 1; // `a` should come after `b`
      } else if (!aIsSpecialCategory && bIsSpecialCategory) {
        return -1; // `a` should come before `b`
      } else {
        // If both are special categories or both are not, sort by category and then by id.
        const categoryComparison = a.product.category.slug.localeCompare(
          b.product.category.slug
        );
        if (categoryComparison !== 0) {
          return categoryComparison;
        } else {
          return a.product.id - b.product.id;
        }
      }
    })
    .map((item, key) => (
      <BasketProductItem
        basket={basket}
        id={item.id}
        count={item.count}
        product={item.product}
        key={key}
        shifts={item.shifts}
        debouncedDispatch={debouncedDispatch}
      />
    ));


  const downloadExcel = () => {
    basketService
      .downloadExcel(uuid)
      .then((response) => {
        // Handle the response here, for example by creating a Blob and a link to download it
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Смета Penumbra.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.error(err));
  };

  const servicesList: JSX.Element[] = basket.services.map((item, key) => (
    <BasketProductServiceItem
      service={item}
      products={basket.products}
      key={key}
    />
  ));

  const [key, setKey] = useState(Math.random());



    return (
        <BasketContext.Provider value={{ loadingPrice, setLoadingPrice }}>
            <>
                <Helmet>
                    <title>{t("basket.helm")}</title>
                    <meta property="og:title" content={t("basket.helm")} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                </Helmet>
                <div className="basket-holder" key={key}>
                    <div className="basket-content">
                        {basket.products.length > 0 ? (
                            <div
                                className="basket-item-holder flex-row justify-content-between"
                                style={{
                                    opacity: 0.3,
                                    paddingLeft: "24px",
                                    paddingRight: "24px",
                                }}
                            >
                                <span
                                    className="align-self-start"
                                    style={{ width: "60px", flexShrink: 0 }}
                                >
                                    {" "}
                                </span>
                                {window.innerWidth >= 1024 ? (
                                    <span
                                        className="align-self-start w-50"
                                        style={{ marginLeft: "8px" }}
                                    >
                                        {t("basket.header.name")}
                                    </span>
                                ) : (
                                    <></>
                                )}
                                <span className="align-self-end me-5">
                                    {t("basket.header.count")}
                                </span>
                                <span className="align-self-end me-5">
                                    {t("basket.header.shifts")}
                                </span>
                                <span
                                    className="align-self-end ms-5 me-5"
                                    style={{ width: "112px !important" }}
                                >
                                    {t("basket.header.price")}
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="products-list" ref={listRef}>
                            <div ref={childRef}>
                                {basket.products.length > 0 ? (
                                    productList
                                ) : (
                                    <div className="d-flex justify-content-center p-3">
                                        {t("basket.empty_list")}
                                    </div>
                                )}
                                {basket.services.length > 0 ? servicesList : <></>}
                            </div>
                            {basket.products.length ? (
                                clearBasket ? (
                                    <BasketClearDelete
                                        resetFields={resetFields}
                                        className="hidden-btn"
                                        products={basket.products}
                                        onCancel={() => setClearBasket(false)}
                                    />
                                ) : (
                                    <button className="btn hidden-btn" onClick={() => clear()}>
                                        {t("basket.clear_basket")}
                                    </button>
                                )
                            ) : (
                                <></>
                            )}

                            {/* {basket.products.length ? <button className='btn hidden-btn' onClick={() => clear()}>{t('basket.clear_basket')}</button> : <></>} */}
                        </div>

                        <div
                            className={`products-price ${childHeight > height ? "box-shadow" : ""
                                }`}
                        >
                            {clearBasket ? (
                                <BasketClearDelete
                                    resetFields={resetFields}
                                    products={basket.products}
                                    onCancel={() => setClearBasket(false)}
                                />
                            ) : (
                                <button className="btn clear" onClick={() => clear()}>
                                    {t("basket.clear_basket")}
                                </button>
                            )}
                            <div className="info">
                                <div className="left">
                                    {haveTime ? (
                                        <div className="right-justify mb-1">
                                            {t("basket.duriation")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.delivery ? (
                                        <div className="right-justify mb-1">
                                            {/* {t('basket.delivery')} */}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon || basket.services_price > 0 ? (
                                        <div className="right-justify opacity mb-1">
                                            {t("basket.products_price")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon ? (
                                        <div className="right-justify mb-1 opacity">
                                            {t("basket.discount")}{" "}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.services_price > 0 ? (
                                        <div className="right-justify mb-1 opacity">
                                            {t("basket.services_price")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                     <div className="right-justify">{t("basket.total_price")}</div>
                                </div>
                                <div className="right">
                                    {haveTime ? (
                                        <div className="right-justify mb-1">
                                            {t("basket.rent_dates", {
                                                dateStart: moment(form.start_time_date).format(
                                                    "DD.MM.YY"
                                                ),
                                                dateEnd: moment(form.end_time_date).format("DD.MM.YY"),
                                            })}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.delivery ? (
                                        <div className="right-justify mb-1 opacity">
                                            {toPrice(
                                                basket.delivery_price + basket.delivery_back_price
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon || basket.services_price > 0 ? (
                                        <div className="right-justify mb-1 opacity">
                                            {toPrice(basket.products_price + basket.discount)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon ? (
                                        <div className="right-justify mb-1 opacity">
                                            -{toPrice(basket.discount)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.services_price > 0 ? (
                                        <div className="right-justify mb-1 opacity">
                                            {toPrice(basket.services_price)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                  {loadingPrice ? <Spinner fullWidth={true} color="#ffffff" /> :   <div className="right-justify">
                                        {toPrice(basket.total_price)}
                </div>}
                                    <div className="right-justify download-xls">
                                        {" "}
                                        <a
                                            href={`https://penumbraback-production-d698.up.railway.app/v1/bag/excel_full/${uuid}/`}
                                            download
                                        >
                                            {t("basket.download_xlsx")}
                                        </a>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="basket-action">
                        <div style={{ width: "100%" }}>
                            {/* <h5 className="mb-4 text-center">{t('basket.form.header')}</h5> */}
                            <span>{t("basket.form.name_placeholder")}</span>
                            <input
                                className="form-input"
                                value={form.name}
                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                                type="text"
                            />

                            <span>{t("basket.form.phone_placeholder")}</span>
                            <div className="d-flex gap-2 form-input">
                                {mask === "*" ? (
                                    <input
                                        className="form-input phone-input"
                                        value={form.phone}
                                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                                        type="text"
                                    />
                                ) : (
                                    <InputMask
                                        className="form-input phone-input"
                                        value={form.phone}
                                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                                        mask={mask}
                                    ></InputMask>
                                )}
                            </div>

                            {delivery ? (
                                <div className="animation-opacity">
                                    <div className="d-flex gap-2">
                                        <label className="form-switch flex-fill animation-opacity mb-3">
                                            <input
                                                type="checkbox"
                                                checked={basket.delivery_back}
                                                onChange={(e) => onDeliveryBack(e.target.checked)}
                                            />
                                            <i></i>
                                            {t("basket.deliver_back")}
                                        </label>
                                        {basket.delivery_back ? (
                                            <label className="form-switch animation-opacity flex-fill mb-3">
                                                <input
                                                    type="checkbox"
                                                    checked={sameAddress}
                                                    onChange={(e) => setSameAddress(e.target.checked)}
                                                />
                                                <i></i>
                                                {t("basket.same_address")}
                                            </label>
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    {/* address */}
                                    <input
                                        className="form-input mb-2"
                                        value={form.address}
                                        onChange={(e) =>
                                            setForm({ ...form, address: e.target.value })
                                        }
                                        placeholder={
                                            t("basket.form.address_placeholder") || "Placeholder"
                                        }
                                        type="text"
                                    />
                                    {sameAddress || !basket.delivery_back ? (
                                        <></>
                                    ) : (
                                        <input
                                            className="form-input mb-2 animation-opacity"
                                            value={form.address_return}
                                            onChange={(e) =>
                                                setForm({ ...form, address_return: e.target.value })
                                            }
                                            placeholder={
                                                t("basket.form.address_return_placeholder") ||
                                                "Placeholder"
                                            }
                                            type="text"
                                        />
                                    )}

                                    <div
                                        onClick={() => setMapPage(true)}
                                        className="delivery-message-btn mb-3"
                                    >
                                        <span
                                            className="icon penumbra-location"
                                            style={{ fontSize: 18 }}
                                        ></span>
                                        <span className="flex-fill mx-2">
                                            {t("basket.form.address_message")}
                                        </span>
                                        <span
                                            className="icon penumbra-chevron-right"
                                            style={{ fontSize: 12 }}
                                        ></span>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}

                            {/* period */}
                            <div className="d-flex justify-content-around mb-2">
                                <span className="mini-header bold">
                                    {t("basket.form.rent_start")}
                                </span>
                                <span className="mini-header bold">
                                    {t("basket.form.rent_finish")}
                                </span>
                            </div>
                            <DatePicker
                                startDate={form.start_time_date}
                                endDate={form.end_time_date}
                                onChange={(start: Date, end: Date) => {
                                    const days = getDaysBetween(start, end) + 1;
                                    setNumDays(days);

                                    const shifts_payload = days <= 0 ? 1 : days;
                                    const payload = basket.products.map((item) => ({
                                        id: item.id,
                                        shifts: shifts_payload,
                                        count: item.count,
                                        product: item.product.id,
                                    }));

                                    basketService.patchProducts(uuid, payload).then(() => {
                                        basketService._getBasket(uuid);
                                    });
                                    setForm({
                                        ...form,
                                        start_time_date: start,
                                        end_time_date: end,
                                    });
                                }}
                            />

                            <button
                                disabled={!valid || isSubmitting}
                                onClick={(e) => handleSubmit(e)}
                                className="bordered-btn form-submit btn btn-black"
                            >
                                {t("basket.order")}
                            </button>
                            <CouponForm basket={basket} uuid={uuid} />
                        </div>
                    </div>

                    {window.innerWidth <= 1024 ? (
                        <div className="products-price-mobile">
                            <div className="info">
                                <div className="left">
                                    {haveTime ? (
                                        <div className="right-justify mb-2">
                                            {t("basket.duriation")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.delivery ? (
                                        <div className="right-justify mb-2">
                                            {/* {t('basket.delivery')} */}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon || basket.services_price > 0 ? (
                                        <div className="right-justify opacity mb-2">
                                            {t("basket.products_price")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon ? (
                                        <div className="right-justify mb-2 opacity">
                                            {t("basket.discount")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.services_price > 0 ? (
                                        <div className="right-justify mb-2 opacity">
                                            {t("basket.services_price")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="right-justify">{t("basket.total_price")}</div>

                                </div>
                                <div className="right">
                                    {haveTime ? (
                                        <div className="right-justify mb-2">
                                            {t("basket.rent_dates", {
                                                dateStart: moment(form.start_time_date).format(
                                                    "DD.MM.YY"
                                                ),
                                                dateEnd: moment(form.end_time_date).format("DD.MM.YY"),
                                            })}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.delivery ? (
                                        <div className="right-justify mb-2 opacity">
                                            {toPrice(
                                                basket.delivery_price + basket.delivery_back_price
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon || basket.services_price > 0 ? (
                                        <div className="right-justify mb-2 opacity">
                                            {toPrice(basket.products_price + basket.discount)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.coupon ? (
                                        <div className="right-justify mb-2 opacity">
                                            -{toPrice(basket.discount)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {basket.services_price > 0 ? (
                                        <div className="right-justify mb-2 opacity">
                                            {toPrice(basket.services_price)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                  {loadingPrice ? <Spinner fullWidth={true} color="#ffffff" /> :   <div className="right-justify">
                                        {toPrice(basket.total_price)}
                                  </div>}
                                    <div className="right-justify download-xls">
                                        {" "}
                                        <a
                                            href={`https://penumbraback-production-d698.up.railway.app/v1/bag/excel_full/${uuid}/`}
                                            download
                                        >
                                            {t("basket.download_xlsx")}
                                        </a>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <button
                    disabled={!valid || isSubmitting}
                    onClick={(e) => handleSubmit(e)}
                    className="btn add-card bordered-btn"
                >
                    {t("basket.order")}
                </button>

                <ThankPageModal
                    show={thankPage}
                    onHide={() => {
                        setThankPage(false);
                        navigate("/");
                    }}
                />
            </>
        </BasketContext.Provider>
    );
}
