export const grant = {
  name: "Введите ФИО",
  name_placeholder: "ФИО",
  phone: "Введите номер телефона",
  phone_placeholder: "+ 7 (777) 999 99 99",
  instagram: "Ссылка на вашу instagram страницу",
  instagram_placeholder: "https://www.instagram.com/penumbrarental",
  portfolio: "Ссылка на портфолио",
  portfolio_placeholder: "https://vimeo.com",
  description: "Описание проекта",
  description_placeholder: "Что планируете снимать?",
  file: "Добавьте файл с тритментом",
  file_placeholder: "Загрузить файл",
  date: "Укажите дату съемок",
  date_placeholder: "2023-01-01",
  comment: "Комментарии",
  comment_placeholder: "",
  policy:
    "Я согласен с политикой конфиденциальности и даю согласие на обработку моих персональных данных",
  submit: "Подать заявку",
  url_error: "Введите правильную ссылку",
  date_error: "Введите правильную дату",
  success_message: "Ваша заявка принята",
  error_header: "Ошибка в форме",
  error_message: "Измените значения формы",
};
