import "./thank-page.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

export default function ThankPageModal(props: any) {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    renderer: "svg",
  };
  return (
    <Modal
      {...props}
      size="l"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="thankPage">
          <div className="mb-2">
            <h1>🎉</h1>
          </div>
          <div className="text header">{t("basket.success_message")}</div>
          <div className="text manager-call">
            {t("basket.success_message_10min")}
          </div>
          {/* <div className="text mb-3">{t('basket.success_message_hint')}</div> */}
          {/* <div className="text">{t('footer.time')}</div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
