import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ArticleState {
  article: any;
}

const initialState: ArticleState = {
  article: null,
};

const slice = createSlice({
  name: "article",
  initialState,
  reducers: {
    startLoadingArtice: () => initialState,
    loadArticle: (state, { payload: product }: PayloadAction<any>) => {
      state.article = product;
    },
  },
});

export const { startLoadingArtice, loadArticle } = slice.actions;

export default slice.reducer;
