export const product = {
  empty: "Empty",
  add_card: "Add",
  to_card: "Added",
  searching: "Search...",
  search_empty: "Found nothing",
  articule: "{{articule}}",
  required_service: "Required: ",
  instruction: "Video instruction",
  set: "Set",
  set_items: "The set consists of:",
  related: "Often rented together",
  specs: "Specs",
  specs_header: "Main characteristics",
};
