import { useEffect, useState } from "react";

const useCountdown = (time: number) => {
  const [counter, setCounter] = useState(time);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  return counter;
};

export { useCountdown };
