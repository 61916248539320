import "./basket-item-delete.scss";
import { BasketService } from "../../../../../services/basket.service";
import { useCountdown } from "../../../../../utils/countdown";
import { useEffect } from "react";
import { localeString } from "../../../../../utils/localeString";
import { useTranslation } from "react-i18next";
import { store } from "../../../../../store/store";
import { loadBasket } from "../../basket.slice";
import { debounce } from "lodash";

export function BasketProductDelete(prop: {
    product: any;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const basketService = new BasketService();
  const uuid: string | null = localStorage.getItem("user_bag_id");
  const time = useCountdown(5);

  useEffect(() => {
    if (time === 0) {
      onDelete();
    }
  }, [time]);

  async function onDelete() {
    prop.onCancel();
      basketService.deleteProductAndUpdate(uuid, prop.product.id).then((updatedBasket) => {
        store.dispatch(loadBasket(updatedBasket));
      })

    }

    return (
        <div className="basket-item-holder basket-item-holder-delete animate-fade">
            <div className="d-flex align-items-center" style={{ width: "100%" }}>
                <div className="countdown">{time}</div>
                <div className="basket-item-info">
                    <div className="message overflow">
                        {t("basket.deleting.message")} "
                        {t(localeString(prop.product.product, "name"))}"
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <button
                    className="btn btn-black white-space"
                    onClick={() => prop.onCancel()}
                >
                    {t("basket.deleting.cancel")}
                </button>
                <button
                    className="btn btn-black white-space"
                    style={{ marginLeft: 12 }}
                    onClick={() => onDelete()}
                >
                    {t("basket.deleting.delete")}
                </button>
            </div>
        </div>
    );
}
